import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import useToken from '../App/useToken'

export default function DownloadCSVPopup(props) {
  const [okToDownload, setOkToDownload] = useState(false)
  const [href, setHref] = useState(null)
  const name = props.itemToDownload ? props.itemToDownload.alias : ''
  const item = props.itemToDownload ? props.itemToDownload : null
  
  const {token} = useToken()
  
  const geojsonToCsv = (geojson) => {
    console.log(geojson)
    const features = geojson.features;
    const csvRows = [];

    // Extract the header (keys from the 'properties' and 'geometry' parts)
    const headers = [
      ...Object.keys(features[0].properties),
      'geometry_coordinates'
    ];
    csvRows.push(headers.join(',')); // Join headers with commas

    // Extract data from each feature
    features.forEach((feature) => {
      const propertyValues = Object.values(feature.properties);
      const geometryCoordinates = JSON.stringify(feature.geometry.coordinates); // Convert coordinates to a string
      const row = [...propertyValues, geometryCoordinates];
      csvRows.push(row.join(',')); // Join row values with commas
    });

    return csvRows.join('\n'); // Combine rows into CSV format
  };
  
  const tryRequire = ()  => {
    
  
    var formData = new FormData();
    formData.append('token', token);
    const url = props.ctx.showShapeData?.current ? "api/get_stand_geojson" : "api/get_flight_geojson"
    console.log(url)
    var config = {
      method: 'post',
      url: url,
      data: props.ctx.showShapeData?.current ? {'token': token, 'AID': props.itemToDownload.AID} : {'token': token, 'id': props.itemToDownload._id}
    };
    axios(config)
    .then((response)=> {
      console.log(response)
      // console.log(response.data.trajectory).464
      
      if(response.data.standtreedata){
        // const json = JSON.stringify(response.data.trajectory, null, 2);
        // console.log(response.data.trajectory)
        const geojson = JSON.parse(response.data.standtreedata)
        const csvContent = geojsonToCsv(geojson);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // const blob = new Blob([response.data.standtreedata], { type: "application/json" });
        // console.log(blob)

        setHref(URL.createObjectURL(blob))
        // console.log("setting download ok")
        setOkToDownload(true)
      } else {
        setHref(null)        
        // console.log("setting download false")

        setOkToDownload(false)
      }
    }).catch((error)=>{
      console.log(error)
      setOkToDownload(false)
      setHref('')
    })
  };
  const handleDownloadClick = () => {
    if(okToDownload && href){
      const link = document.createElement('a');
      link.href = href;
      const alias = props.itemToDownload.name+'.csv'
      console.log(alias)
      link.download = alias
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      props.setDownloadCSVDialogShow(false);
    }
  };
  let title = okToDownload ? "Download CSV data of stand" + props.itemToDownload ? props.itemToDownload.alias : '' : "CSV does not exist for this stand" 
  useEffect(()=>{
    console.log(props.downloadCSVDialogShow)
    console.log(props.itemToDownload)
    if(props.downloadCSVDialogShow && props.itemToDownload){
      tryRequire()
    }
  }, [props.downloadCSVDialogShow])
  return (
    <Modal
      show={props.downloadCSVDialogShow}
      onHide={() => props.setDownloadCSVDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={() => props.setDownloadCSVDialogShow(false)}>Cancel</Button>
          <Button
          disabled={!okToDownload} 
          // download={'gwf.csv'}
          onClick={handleDownloadClick}>
              Download
          </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DownloadCSVPopup.propTypes = {
  ctx: PropTypes.object.isRequired,
  itemToDownload: PropTypes.object.isRequired,
  downloadCSVDialogShow: PropTypes.object.isRequired,
  setDownloadCSVDialogShow: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired
}