import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import useToken from '../App/useToken'

export default function DownloadFlightPathDialogPopup(props) {
  const [okToDownload, setOkToDownload] = useState(false)
  const [href, setHref] = useState(null)
  const name = props.itemToDownload ? props.itemToDownload.name : ''
  const item = props.itemToDownload ? props.itemToDownload : null
  
  const {token} = useToken()
  const tryRequire = ()  => {
    var formData = new FormData();
    formData.append('token', token);
    formData.append('name', item.name);
    formData.append('id', item._id);
    formData.append('DOF', item.DOF);
    formData.append('droneId', item.droneId);
    formData.append('customerId', item.customerId);
    var config = {
      method: 'post',
      url: "file-handler/get_trajectory_geojson",
      data: formData
  };
    axios(config)
    .then((response)=> {
      // console.log(response)
      // console.log(response.data.trajectory)
      if(response.data.trajectory){
        // const json = JSON.stringify(response.data.trajectory, null, 2);
        // console.log(response.data.trajectory)

        const blob = new Blob([response.data.trajectory], { type: "application/json" });
        // console.log(blob)

        setHref(URL.createObjectURL(blob))
        // console.log("setting download ok")
        setOkToDownload(true)
      } else {
        setHref(null)        
        // console.log("setting download false")

        setOkToDownload(false)
      }
    }).catch((error)=>{
      console.log(error)
      setOkToDownload(false)
      setHref('')
    })
  };
  let title = okToDownload ? "Download flight path of " + props.itemToDownload ? props.itemToDownload.alias : '' : "Geojson does not exist for this item" 
  useEffect(()=>{
    if(props.downloadFlightPathDialogShow){
      tryRequire()
    }
  }, [props.downloadFlightPathDialogShow])
  return (
    <Modal
      show={props.downloadFlightPathDialogShow}
      onHide={() => props.setDownloadFlightPathDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={() => props.setDownloadFlightPathDialogShow(false)}>Cancel</Button>
          <Button
          disabled={!okToDownload} 
          href={href} 
          download={name+'_trajectory.geojson'} 
          onClick={() => { 
            props.setDownloadFlightPathDialogShow(false)}}>
              Download
          </Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DownloadFlightPathDialogPopup.propTypes = {
  itemToDownload: PropTypes.object.isRequired,
  downloadFlightPathDialogShow: PropTypes.bool.isRequired,
  setDownloadFlightPathDialogShow: PropTypes.func.isRequired,
}