import React, { useState } from 'react'
import axios from "axios";

export default function useToken() {
  const getToken = () => {
    return sessionStorage.getItem('token')
  }

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    sessionStorage.setItem('token', userToken)
    setToken(userToken)
  }
  const removeToken = () => {
    sessionStorage.removeItem('token')
    setToken(null)
  }

  const fetchAndSetCustomer = async (token, setLoading) => {
    var formData = new FormData();
        formData.append('token', token);
        var config = {
            method: 'post',
            url: "api/get_customer_by_token",
            data: formData
        };
        const res = await axios(config)
        .then(response => {
            if(response.data.customer){ 
                console.log(response.data.customer)
                saveCustomer(response.data.customer)
                setLoading(false)
                return response.data.customer  
            }
            setLoading(true)
            return null
            
        })            
        .catch(error => {
            console.log('error', error)
            setLoading(true)
            return null
        })
        return res
  }
  const getCustomer = () => {
    return JSON.parse(sessionStorage.getItem('customer'))
  }
  const [customer, setCustomer] = useState(getCustomer())

  const saveCustomer = customer => {
    const customerData = JSON.stringify(customer)
    sessionStorage.setItem('customer', customerData)
    setCustomer(customerData)
  }
  const removeCustomer = () => {
    sessionStorage.removeItem('customer')
    setCustomer(null)
  }

  
  return {
    setToken: saveToken,
    removeToken,
    token,
    setCustomer: saveCustomer,
    removeCustomer,
    getCustomer,
    fetchAndSetCustomer
  }
}
