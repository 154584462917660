import React, {useState,useEffect,useRef}  from 'react';
import { Card, ListGroup, Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { Element } from "react-scroll"

export default function BatteryList({batteries, selectItem, setAddNew, addNew}) {
    const [itemlist, setItemlist] = useState([])
    useEffect(() => {
        if(batteries && Array.isArray(batteries)){
            let batteryItems = batteries.map(item => {
                return (
                        <ListGroup.Item
                            action
                            active={false}
                            eventKey={item._id} 
                            key={item._id} 
                            onClick={(e) => {
                                selectItem(item) 
                                e.preventDefault()
                                e.stopPropagation()}}
                        >
                            {item.serialNumber}
                        </ListGroup.Item>
                )
            })
            setItemlist(batteryItems)
            // console.log(items)
        }
    }, [batteries])

    return (
        <div style={{
            display: "flex",
            height: "100%",
          }}>
             <Card 
                bg={"dark"}
                key={"AdminBatteryList"}
                text={"white"}
                style={{minWidth: "95%", height: "90%", margin: "1em", border: "3px outset #0f5132"}}
                className="mb-3">
                    <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>Battery list
                    
                    </Card.Header>
                    <Card.Body>
                    <Element name="adminViewBatteryList" className="element" id="containerElement" style={{
                    position: "relative",
                    height: "450px",
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "Hidden",
                    marginBottom: "1.5em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                    }}>
                        
                        <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                                {itemlist ? itemlist : "loading..."}
                        </ListGroup>
                    </Element>
                </Card.Body>
                <Button variant="secondary" onClick={() => setAddNew(!addNew)} className="df-button">
                    {addNew ? "Cancel" : "Add new"}
                </Button>
                </Card>
        </div>
    )
}
