import React, {useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, SplitButton, Collapse } from 'react-bootstrap'

export default function TreeTableHeader(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if(props.open){
      setOpen(true)
    }
  }, [props.open])
  const table = props.body.length > 0 ? 
  (
    <>
      <Table striped bordered hover variant="dark" size='sm' responsive="md" style={{marginBottom: "0", borderBottom: "2px solid black" }}>
        <thead onClick={() => setOpen(!open)} style={{backgroundColor:"#212529"}}>
            <tr>
              <th>{props.interval}</th>
              <th style={{textAlign:"center", width:"10%"}}>DBH</th>
              <th style={{textAlign:"center", width:"13%"}}>Volume</th>
              <th style={{textAlign:"center", width:"13%"}}>Height</th>
              <th style={{textAlign:"center", width:"12%"}}>(lon, lat)</th>
              <th style={{textAlign:"center", width: "22%"}}>
                <SplitButton
                  disabled
                  size="sm"
                  id={`dropdown-split-variants`}
                  variant={"outline-secondary"}
                  title={`(${props.body.length} items)`}
                />
              </th>
            </tr>
        </thead>
      </Table>
      <Collapse in={open}>
      <div style={{maxHeight:"300px", overflowY: "overlay", border: "1px solid #00d199"}}>
        <Table striped bordered hover variant="dark" size='sm' responsive="md" style={{marginBottom: "0", borderBottom: "2px solid black"}}>
          <tbody>
            {props.body}
          </tbody>
        </Table>
      </div>
    </Collapse>
   </>
  ) : null
  return (
    table
  )
}

TreeTableHeader.propTypes = {
  open: PropTypes.bool.isRequired,
  interval: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
}