import React, {useState,useEffect,useRef}  from 'react';
import { Card, ListGroup, Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { Element } from "react-scroll"
import PropTypes from 'prop-types'
import { formatPythonDate } from '../../Helper/Helper';

export default function DroneItems(props) {
    const [itemlist, setItemlist] = useState([])
    useEffect(() => {
        if(Array.isArray(props.items)){
            const items = props.items.map(i => {
                // console.log(i)
                const lastOnline = formatPythonDate(i.lastOnline)
                const status = i.online ? i.status : i.lastOnline ? "last online: "+lastOnline : i.status
                return (
                    <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                        <ListGroup.Item
                            action
                            style={i.online || i.name === "df-drone-01" ? {backgroundColor: 'green'} : {backgroundColor: 'red'}  }
                            eventKey={i._id} 
                            key={i._id} 
                            onClick={() => {
                                // console.log(i)
                                props.selectItem(i)
                            }}
                            
                            >
                            {i.name + ' - ' + status}
                        </ListGroup.Item>
                        <Dropdown.Menu variant="dark">
                                <Dropdown.Item disabled={true} eventKey={`ExportCSV-${i._id}`}>
                                    Does nothing
                                </Dropdown.Item>
                                
                        </Dropdown.Menu>
                    </Dropdown>
                )
            })
            setItemlist(items)
            // console.log(items)
        }
    }, [props.items])
   
    return (
        <div style={{
            display: "flex",
            height: "100%",
          }}>
             <Card 
                bg={"dark"}
                key={"AdminListItems"}
                text={"white"}
                style={{minWidth: "95%", height: "90%", margin: "1em", border: "3px outset #0f5132"}}
                className="mb-3">
                    <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>{props.type}</Card.Header>
                    <Card.Body>
                    <Element name="adminViewListItems" className="element" id="containerElement" style={{
                    position: "relative",
                    height: "450px",
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "Hidden",
                    marginBottom: "1.5em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                    }}>
                        
                        <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                                {itemlist ? itemlist : "loading..."}
                        </ListGroup>
                    </Element>
                </Card.Body>
                </Card>
        </div>
    )
}


DroneItems.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    selectItem: PropTypes.func.isRequired
  }

// function usersDropdown(items){
//     return (
//         <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
//             <ListGroup.Item
//                 action
//                 active={false}
//                 eventKey={item._id} 
//                 key={item._id} 
//                 onClick={(e) => {
//                     handleClick(item) 
//                     e.preventDefault()
//                     e.stopPropagation()}}
//                 disabled={item.processState !== "Finished"}>
//                 {name + proccessing}
//             </ListGroup.Item>
//             <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

//             <Dropdown.Menu variant="dark">
//                 {items}
//             </Dropdown.Menu>
//         </Dropdown>
//     )
//   }