import React, {useState,useEffect, useRef}  from 'react';
import { Card } from "react-bootstrap"

import PropTypes from 'prop-types'
import axios from "axios";
import useToken from '../../App/useToken';

const dbhFilter = 0.7
export default function ManagingReport(props) {
    const {token} = useToken()
    
    useEffect(() => {
        console.log(props.reportTitle)
    },props.reportTitle)
    return (
        <div style={{
            height: "95%",
            width: "95%",
          }}>
           
                <Card 
                bg={"dark"}
                key={"ForestDetailsItems"}
                text={"white"}
                style={{minWidth: "200px", height: "100%", margin: "1em", border: "3px outset #0f5132"}}
                className="mb-3">
                 <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>Customize report</Card.Header> 
                 <form onSubmit={(e) => e.preventDefault()}>  
                    <FormInput description="Report title" placeholder="Optional, set report title" type="text" value={props.reportTitle} setValue={props.setReportTitle}/>
                </form>
                </Card>
        </div>
    )
}

ManagingReport.propTypes = {
    setReportTitle: PropTypes.func.isRequired,
    reportTitle: PropTypes.string.isRequired
}


const FormInput = props => (
    <div class="row">
      <label>{props.description}</label>
      <input type={props.type} defaultValue={props.value} onChange={e => props.setValue(e.target.value)} placeholder={props.placeholder}/>
    </div>  
  )