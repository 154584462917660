import React, {useState,useEffect,useRef}  from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types'

import ItemList from '../GeneralView/ItemList/ItemList';
import useToken from '../../App/useToken';
import MeasurementDropZone from './measurementDropZone';


export default function UpdateMeasurement(props) {
    const {token} = useToken()
    const [selectedItem, setSelectedItem] = useState(null)
    const newSelectedItem = (item) => {
        setSelectedItem(item)
    }

    const [dropzoneActive, setDropzoneActive] = useState(false)
    const changeDropzoneActive = (active) => {
        setDropzoneActive(active)
    }
    const dropZone = ( 
        <MeasurementDropZone
          show={dropzoneActive}
          onHide={changeDropzoneActive}
          token={token}
          selectedItem={selectedItem}
        />
        )
    return (
        <div style={{
            display: 'flex',
            height: "100%",
            width: "100%"
          }}>
            <div style= {{width: "30%", height: "100%"}}>
                <ItemList items={props.bagData} selectItem={newSelectedItem} type={props.type}/>
                <Button disabled={!selectedItem} onClick={() => changeDropzoneActive(true)}>upload measurement</Button>
            </div>
            {dropZone}
             {/* List view left, Selection, edit, delete.
                Selected item view right
                Edit, add new bottom
                EditView (instead of Selected view)
             */
             
             }
        </div>
    )
}

UpdateMeasurement.protoTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    bagData: PropTypes.array.isRequired
}