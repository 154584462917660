import React, {useState,useEffect}  from 'react';
import axios from "axios";
import PropTypes from 'prop-types'
import { ListGroup, Dropdown, Button, ButtonGroup, Form } from 'react-bootstrap'

import { slide as Menu } from 'react-burger-menu'
import {rowStyles, styles} from './styles'
import {useMediaQuery} from '../../helpers/mediaQuery';
import { FaFilter } from 'react-icons/fa'
import DeleteDialogPopup from '../DialogPopup/DeleteDialogPopup';
import DownloadDialogPopup from '../DialogPopup/DownloadDialogPopup';
import ChangeAliasDialogPopup from '../DialogPopup/ChangeAliasDialogPopup';
import useToken from '../App/useToken';
import Helper, { areaIsSynced } from '../Helper/Helper';
import AddFlightToStandPopup from '../DialogPopup/AddFlightToStandPopup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Access, hasAccess } from '../Helper/Helper';
import DeleteStandDialogPopup from '../DialogPopup/DeleteStandDialogPopup ';
var testEnv = process.env.REACT_APP_TEST_ENV

export default function SideNavBar(props) {
    const isRowBased = useMediaQuery('(max-width: 420px)');
    const {token, getCustomer} = useToken()
    const [customer, setCustomer] = useState(getCustomer())


    const [filterCustomerData, setFilterCustomerData] = useState('')

    const [shapeData, setShapeData] = useState([])
    const [bagfiles, setBagfiles] = useState(testEnv === 'false' ? []: [
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'1'} 
            key={'1'} 
            onClick={() => null}>
                A very long name onece again. sooo very long
            </ListGroup.Item>
        ),
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'2'} 
            key={'2'} 
            onClick={() => null}>
                Short name
            </ListGroup.Item>
        ),
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'3'} 
            key={'3'} 
            onClick={() => null}>
                A very long name onece again. sooo very long
            </ListGroup.Item>
        ),
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'4'} 
            key={'4'} 
            onClick={() => null}>
                Short name
            </ListGroup.Item>
        ),
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'5'} 
            key={'5'} 
            onClick={() => null}>
                A very long name onece again. sooo very long
            </ListGroup.Item>
        ),
        (
            <ListGroup.Item
            action
            style={{marginBottom: "1em"}}
            eventKey={'6'} 
            key={'6'} 
            onClick={() => null}>
                Short name
            </ListGroup.Item>
        )
    ])

    const handleClick = (item) => {
        if(props.ctx.showShapeData?.current){
            props.ctx.selectShapeData(item)
        } else {
            props.setSelectedBagfile(item)
        }
    }

    const [deleteDialogShow, setDeleteDialogShow] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)

    const handleDelete = (item) => {
        var formData = new FormData();
        formData.append('token', token);
        formData.append('name', item.name);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        formData.append('id', item._id);
        var config = {
            method: 'post',
            url: "file-handler/delete_item",
            data: formData
        };
        axios(config).then(response => {
            // console.log(response)
            if(props.selectedBagfile && itemToDelete._id === props.selectedBagfile._id) {
                props.setSelectedBagfile(null)
            }
            setDeleteDialogShow(false)
            setItemToDelete(null)
        }).catch(error => {
          console.log('error', error)
        })   
    }
    const openDeleteStandDialog = (item) => {
        setStandToDelete(item)
        setDeleteStandDialogShow(true)
        
    }
    const [deleteStandDialogShow, setDeleteStandDialogShow] = useState(false)
    const [standToDelete, setStandToDelete] = useState(null)

    const handleDeleteStand = (item) => {
        var formData = new FormData();
        formData.append('token', token);
        formData.append('AID', item.AID);
        formData.append('alias', item.alias);
        formData.append('customerId', item.customerId);
        item.fileName && formData.append('fileName', item.fileName);

        var config = {
            method: 'post',
            url: "file-handler/delete_stand",
            data: formData
        };
        axios(config).then(response => {
            // console.log(response)
            if(props.ctx?.selectedShapeData.current && standToDelete._id === props.ctx?.selectedShapeData.current._id) {
                props.ctx.selectShapeData({_id: "", AID: ""})
            }
            setDeleteStandDialogShow(false)
            setStandToDelete(null)
        }).catch(error => {
          console.log('error', error)
        })   
    }
    const setDemo = (item) => {
        const isDemo = !item?.isDemo
        const data = {
            "token": token,
            "isDemo": isDemo,
            "_id": item._id
        }
        
        var config = {
            method: "post",
            url: "api/update_dronefile",
            data: data
        };

        axios(config).then(response => {
            
        }).catch(error => {
          console.log("error", error)
        })
    }
    const setStandDemo = (item) => {
        const isDemo = !item?.isDemo
        const data = {
            "token": token,
            "isDemo": isDemo,
            "_id": item._id
        }
        
        var config = {
            method: "post",
            url: "api/updateSurveyArea",
            data: data
        };

        axios(config).then(response => {
            
        }).catch(error => {
          console.log("error", error)
        })
    }
    const setReviewed = (item) => {
        const isReviewed = !item?.isReviewed
        const data = {
            "token": token,
            "isReviewed": isReviewed,
            "_id": item._id
        }
        
        var config = {
            method: "post",
            url: "api/update_dronefile",
            data: data
        };

        axios(config).then(response => {
            
        }).catch(error => {
          console.log("error", error)
        })
    }
    const openDeleteDialog = (item) => {
        setItemToDelete(item)
        setDeleteDialogShow(true)
        
    }
  

    const [downloadDialogShow, setDownloadDialogShow] = useState(false)
    const [itemToDownload, setItemToDownload] = useState(null)
    const openDownloadDialog = (item) => {
        setItemToDownload(item)
        setDownloadDialogShow(true)       
    }

    const [addFlightToStandDialogShow, setAddFlightToStandDialogShow] = useState(false)
    const [itemToAdd, setItemToAdd] = useState(null)
    const openAddFlightToStandPopup = (item) => {
        setItemToAdd(item)
        setAddFlightToStandDialogShow(true)       
    }

    const [changeAliasDialogShow, setChangeAliasDialogShow] = useState(false)
    const [itemToChangeAlias, setItemToChangeAlias] = useState(null)
    const openChangeAliasDialog = (item) => {
        // console.log(item)
        setItemToChangeAlias(item)
        setChangeAliasDialogShow(true)
        
    }
    const cancelProcessing = (item) => {
        if(item.currentTaskId){
            const data = {
                "id": item._id,
                "name": item.name,
                "customerId": item.customerId,
                "droneId": item.droneId,
                "DOF": item.DOF,
                "currentTaskId": item.currentTaskId,
                "token": token
            }
            //console.log(data)
            var config = {
                method: "post",
                url: "api/cancel_task",
                data: data
            }
            axios(config).then(response => {
                // console.log(response)
            }).catch(error => {
                console.log(error)
            })
        } else {
            console.log("not currently processing")
        }
    }
    const restartConvertion = (item) => {
        console.log("restarts...")
        var formData = new FormData();
        formData.append('token', token);
        formData.append('filename', item.name);
        formData.append('id', item._id);
        formData.append('cause', item.processState);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        var config = {
            method: 'post',
            url: "file-handler/restartConvertion",
            data: formData
        };
        axios(config).then(resopnse => {
            // console.log(resopnse)
        })
    }
    const createFlightReport = (item) => {
        console.log("create flight report...")
        var formData = new FormData();
        formData.append('token', token);        
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        var config = {
            method: 'post',
            url: "file-handler/createFlightReport",
            data: formData
        };
        axios(config).then(resopnse => {
            // console.log(resopnse)
        })
    }
    
    const restartPreProcessing = (item) => {
        console.log("restarts...")
        var formData = new FormData();
        formData.append('token', token);
        formData.append('filename', item.name);
        formData.append('id', item._id);
        formData.append('cause', item.processState);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        var config = {
            method: 'post',
            url: "file-handler/restartPreProcessing",
            data: formData
        };
        axios(config).then(resopnse => {
            // console.log(resopnse)
        })
    }
    const rerunPreProcessing = (item) => {
        console.log("restarts...")
        var formData = new FormData();
        formData.append('token', token);
        formData.append('filename', item.name);
        formData.append('id', item._id);
        formData.append('cause', item.processState);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        var config = {
            method: 'post',
            url: "file-handler/rerunPreProcessing",
            data: formData
        };
        axios(config).then(resopnse => {
            // console.log(resopnse)
        })
    }
    const restartPotreeConverter = (item) => {
        console.log("restarts...")
        var formData = new FormData();
        formData.append('token', token);
        formData.append('filename', item.name);
        formData.append('id', item._id);
        formData.append('cause', item.processState);
        formData.append('DOF', item.DOF);
        formData.append('droneId', item.droneId);
        formData.append('customerId', item.customerId);
        var config = {
            method: 'post',
            url: "file-handler/restartPotreeConverter",
            data: formData
        };
        axios(config).then(resopnse => {

            console.log(resopnse)
        })
    }
    const updateSideBar = () => {
        if(props.ctx.showShapeData?.current){
            //console.log(props.ctx.surveyArea?.current)
            if(props.ctx.surveyArea?.current){
                setShapeData(props.ctx.surveyArea?.current.map((item) => {
                    let synchedDrones = ''
                    // console.log(props.ctx.drones)
                    props.ctx.drones?.forEach(drone => {
                        if(drone.areaFiles && areaIsSynced(drone.areaFiles, item._id)){
                            synchedDrones = synchedDrones + drone.name + ', '
                        }
                    });
                    if(synchedDrones != ''){
                        synchedDrones = synchedDrones.slice(0, -2)
                    } else {
                        synchedDrones = 'not synched'
                    }
                    // const status = Helper.getStatus(item.processState)
                    // const proccessing = status !== '' ? ' - ' + status : ''
                    // // String(item.processState).includes('Failed') ? " - "+item.processState :
                    // //   item.processState !== "Finished" ? " - "+ item.processState : ""
                    // const hasAlias = item.alias && item.alias !== ""
                    // const name = hasAlias ?  item.alias : item.name
                    return (
                        <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                            <ListGroup.Item
                            action
                            variant={props?.ctx.selectedShapeData?.current?._id === item._id ? "info" : item.mergedState === "merged" ? 'success' : item.mergedState === "newData" ? 'warning' : ""}
                            active={props?.ctx.selectedShapeData?.current?._id === item._id}
                            eventKey={item._id} 
                            key={item._id} 
                            onClick={(e) => {
                                handleClick(item) 
                                e.preventDefault()
                                e.stopPropagation()}}
                            >
                                {item.alias +" - "+ synchedDrones}
                            </ListGroup.Item>
                            <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

                                <Dropdown.Menu variant="dark">
                                    { hasAccess(customer) && <Dropdown.Item eventKey={`demo-${item._id}`} onClick={() => {setStandDemo(item)}}>
                                        {item.isDemo ? "Remove as demo" : "Set as demo"}
                                    </Dropdown.Item>}
                                    { hasAccess(customer) && <Dropdown.Item eventKey={`demo-${item._id}`} onClick={() => {openDeleteStandDialog(item)}}>
                                        {"Remove stand..."}
                                    </Dropdown.Item>}
                                    {/* {customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                        Download pcd...
                                    </Dropdown.Item>}
                                    { customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {setDemo(item)}}>
                                        {item.isDemo ? "Remove as demo" : "Set as demo"}
                                    </Dropdown.Item>}
                                    <Dropdown.Divider />
                                    {customer.customerId === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`RetryProcessing-${item._id}`} onClick={() => {restartConvertion(item)}}>
                                        Restart processing
                                    </Dropdown.Item>}
                                    <Dropdown.Item eventKey={`ChangeAlias-${item._id}`} onClick={() => {openChangeAliasDialog(item)}}>
                                        Change alias...
                                    </Dropdown.Item>
                                    {customer.customerId === '101010' && <Dropdown.Item disabled={!(item.processState.includes("Finished") || String(item.processState).includes('Failed'))} eventKey={`Delete-${item._id}`} onClick={() => {openDeleteDialog(item)}}>
                                        Delete...
                                    </Dropdown.Item>} */}
                                </Dropdown.Menu>
                        </Dropdown>
                        )
                }))
            }
        } else {
            if(props.bagData) {
                // console.log(props.ctx?.filterBagData)
                setBagfiles(props.bagData.map(item => {
                    if(props.ctx?.filterBagData === 'notReviewed'){
                        if(item.isReviewed){
                            return
                        }
                    } else if(props.ctx?.filterBagData === 'reviewed'){
                        if(!item.isReviewed){
                            return
                        }
                    }
                    const status = Helper.getStatus(item.processState)
                    const proccessing = status !== '' ? ' - ' + status : ''
                    // String(item.processState).includes('Failed') ? " - "+item.processState :
                    //   item.processState !== "Finished" ? " - "+ item.processState : ""
                    const hasAlias = item.alias && item.alias !== ""
                    const name = hasAlias ?  item.alias : item.name
                    
                    return (
                        <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                            <ListGroup.Item
                            action
                            variant={props?.selectedBagfile?._id === item._id ? "info" : ""}
                            active={props?.selectedBagfile?._id === item._id}
                            eventKey={item._id} 
                            key={item._id} 
                            onClick={(e) => {
                                handleClick(item) 
                                e.preventDefault()
                                e.stopPropagation()}}
                            disabled={!item.processState.includes("Finished")}
                            >
                                {name + proccessing}
                            </ListGroup.Item>
                            <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

                                <Dropdown.Menu variant="dark">
                                    {hasAccess(customer, Access.DOWNLOAD_PCD.number) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                        Download pcd...
                                    </Dropdown.Item>}
                                    { hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes("Failed") || item.processState.includes("Finished"))} eventKey={`demo-${item._id}`} onClick={() => {setDemo(item)}}>
                                        {item.isDemo ? "Remove as demo" : "Set as demo"}
                                    </Dropdown.Item>}
                                    { hasAccess(customer) && <Dropdown.Item eventKey={`review-${item._id}`} onClick={() => {setReviewed(item)}}>
                                        {item.isReviewed ? "Remove as reviewed" : "Set as reviewed"}
                                    </Dropdown.Item>}
                                    <Dropdown.Divider />
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished") || item.processState.includes("Canceled"))} eventKey={`RestartConvertion-${item._id}`} onClick={() => {restartConvertion(item)}}>
                                        Restart processing
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished") || item.processState.includes("Canceled"))} eventKey={`RestartPreProcessing-${item._id}`} onClick={() => {restartPreProcessing(item)}}>
                                        Restart pre processing
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item eventKey={`createFlightReport-${item._id}`} onClick={() => {createFlightReport(item)}}>
                                        Create flight report
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished") || item.processState.includes("Canceled"))} eventKey={`RerunPreProcessing-${item._id}`} onClick={() => {rerunPreProcessing(item)}}>
                                        Update measurements
                                    </Dropdown.Item>}
                                    {hasAccess(customer, Access.ADD_TO_STAND.number) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished") || item.processState.includes("Canceled"))} eventKey={`AddToStand-${item._id}`} onClick={() => {openAddFlightToStandPopup(item)}}>
                                        Add to Stand
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished") || item.processState.includes("Canceled"))} eventKey={`RetryPotree-${item._id}`} onClick={() => {restartPotreeConverter(item)}}>
                                        Restart potree converter
                                    </Dropdown.Item>}
                                    {customer.customerId !== '111011' && <Dropdown.Item eventKey={`ChangeAlias-${item._id}`} onClick={() => {openChangeAliasDialog(item)}}>
                                        Change alias...
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!item.currentTaskId} eventKey={`Cancel-${item._id}`} onClick={() => {cancelProcessing(item)}}>
                                        Cancel processing
                                    </Dropdown.Item>}
                                    {hasAccess(customer) && <Dropdown.Item disabled={!(item.processState.includes("Finished") || String(item.processState).includes('Failed') || item.processState.includes("Canceled"))} eventKey={`Delete-${item._id}`} onClick={() => {openDeleteDialog(item)}}>
                                        Delete...
                                    </Dropdown.Item>}
                                </Dropdown.Menu>
                        </Dropdown>
                        )
                }))
            }
        }
    }
    useEffect(() => {
        updateSideBar()
        // console.log("updateing sidenavbar")
    }, [props.bagData, props.selectedBagfile, props.ctx.showShapeData?.current, props.ctx.shapeData?.current, props.ctx.selectedShapeData?.current, props.ctx?.filterBagData, props.ctx?.drones, props.ctx?.surveyArea.current])
    const dropZoneButton = customer.customerId === '101010' ? 
        <Button style={{margin: "0 1em", padding: "1em", width: '2em' }} variant="primary" active="false" onClick={() => props.changeDropzoneActive(true)}>Add new file</Button> :
        null
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={e => {
            e.preventDefault();
            onClick(e);
            }}
        >
            <FaFilter/>
            {children}
        </a>
    ))
    const listView = bagfiles.length < 1 ? <h3>loading...</h3> : bagfiles
    return (
        <>
            
            <Menu styles={isRowBased ? rowStyles : styles} noOverlay={true} right={true}>
                <Button style={{padding: "0.5em"}}
                    onClick={props.ctx.toggleShowShapeData}> 
                    {props.ctx.showShapeData?.current ?  "Show individual flights" : "Show stand areas"}
                </Button>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Show stand area on map"
                    checked={props.showStands}
                    onClick={() => props.setShowStands(!props.showStands)}
                />
                <div style={{display: 'flex'}}>
               {hasAccess(customer) && <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                    Customer filter 
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{height: "360px", overflowY: "auto"}}>
                        {props.selectCustomers}
                    </Dropdown.Menu>
                </Dropdown>}
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => props.ctx.setFilterBagData('')} href="#" eventKey="All">No filter</Dropdown.Item>
                        {hasAccess(customer) && <Dropdown.Item variant={props.ctx.filterBagData === 'notReviewed' ? "info" : ""} onClick={() => props.ctx.setFilterBagData('notReviewed')} href="#" eventKey="NotReviewed">Not reviewed</Dropdown.Item>}
                        {hasAccess(customer) && <Dropdown.Item variant={props.ctx.filterBagData === 'reviewed' ? "info" : ""} onClick={() => props.ctx.setFilterBagData('reviewed')} href="#" eventKey="Reviewed">Reviewed</Dropdown.Item>}
                        {hasAccess(customer) && <Dropdown.Item variant={props.ctx.filterAID === 'NotInStand' ? "info" : ""} onClick={() => props.ctx.setFilterAID('NotInStand')} href="#" eventKey="NotInStand">Not in a stand</Dropdown.Item>}
                        {hasAccess(customer) && <Dropdown.Item variant={props.ctx.filterAID === 'OnlyInStand' ? "info" : ""} onClick={() => props.ctx.setFilterAID('OnlyInStand')} href="#" eventKey="OnlyInStand">Only items in stands</Dropdown.Item>}
                    
                    </Dropdown.Menu>
                </Dropdown>
                </div>
                <div name="forestDetailsItems" className="element" id="containerElement" style={{
                    position: 'relative',
                    height: "90%",
                    width: "95%",
                    overflowY: 'auto',
                    overflowX: 'Hidden',
                    marginBottom: '1.5em',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                    }}>
                    
                    <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                        {props.ctx.showShapeData?.current ? shapeData : listView } 
                    </ListGroup> 
                </div>
                <Button style={{margin: "0 1em", padding: "1em" }} variant="primary" active="false" onClick={() => props.changeDropzoneActive(true)}>Add planned area...</Button>               
            </Menu>
            <DeleteDialogPopup itemToDelete={itemToDelete} handleDelete={handleDelete} deleteDialogShow={deleteDialogShow} setDeleteDialogShow={setDeleteDialogShow}/>   
            <DeleteStandDialogPopup standToDelete={standToDelete} handleDeleteStand={handleDeleteStand} deleteStandDialogShow={deleteStandDialogShow} setDeleteStandDialogShow={setDeleteStandDialogShow}/>     

            <DownloadDialogPopup ctx={props.ctx} itemToDownload={itemToDownload} setItemToDownload={setItemToDownload} downloadDialogShow={downloadDialogShow} setDownloadDialogShow={setDownloadDialogShow}/>     
            <ChangeAliasDialogPopup itemToChangeAlias={itemToChangeAlias} changeAliasDialogShow={changeAliasDialogShow} setChangeAliasDialogShow={setChangeAliasDialogShow}/>     
            <AddFlightToStandPopup ctx={props.ctx} itemToAdd={itemToAdd} addFlightToStandDialogShow={addFlightToStandDialogShow} setAddFlightToStandDialogShow={setAddFlightToStandDialogShow} />
        </>
      );
}

SideNavBar.propTypes = {
    bagData: PropTypes.array,
    selectedBagfile: PropTypes.object,
    setSelectedBagfile: PropTypes.func.isRequired,
    changeDropzoneActive: PropTypes.func.isRequired,
    setFilterCustomerData: PropTypes.func.isRequired,
    selectCustomers: PropTypes.array.isRequired,
    ctx: PropTypes.object.isRequired,
    setShowStands: PropTypes.func.isRequired,
    showStands: propTypes.bool.isRequired
}