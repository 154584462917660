import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";

import './App.css'

import Login from '../Login/Login';
import useToken from './useToken';
import ControlledTabs from '../Tabs/Tabs';
import { appStyle, narrowAppStyle } from './AppStyle'
import { useMediaQuery } from '../../helpers/mediaQuery';
import useCoord from './useCoord';

var testEnv = process.env.REACT_APP_TEST_ENV
export default function App() {
  const isRowBased = useMediaQuery('(max-width: 420px)');
  const isRowBasedFontSize = useMediaQuery('(max-height: 1000px)');
  
  const { token, setToken, removeToken, setCustomer, removeCustomer, fetchAndSetCustomer } = useToken();
  const { setCoord, removeCoord } = useCoord();
  const [loading, setLoading] = useState(true)
  const onLogoutClick = () => {
    removeToken()
    removeCoord()
    removeCustomer()
    setLoading(true)
  }
  const verifyUser = (token) => {
      // Retrieve customer 

      //If no customer logout

      //else set "userVerified"
      if(token){
        removeCustomer()
        const customer = fetchAndSetCustomer(token, setLoading)
        if(!customer){
          console.log("Invalid token")
          onLogoutClick()
        }
      }
  }

  useEffect(()=>{
    setLoading(true)
    verifyUser(token)
  },[])
  var centerImg = isRowBased ? "center" : ""
  var fontSize = isRowBasedFontSize ? "0.8em" : "1em"

  const frontPage = loading ? <Login setLoading={setLoading} setToken={setToken} setCoord={setCoord} setCustomer={setCustomer} /> : (
    <div style={{margin: 0, height: "100%", fontSize: fontSize}}>
      <header style={isRowBased ? narrowAppStyle : appStyle}>
        <img style={{margin:"1.5em", maxWidth:"370px", minWidth: "250px", alignSelf: centerImg}} width={"25%"} src='deepforestry-logo.png' alt='deepforestrylogo'></img>
        <Button variant="outline-secondary" onClick={onLogoutClick} style={{right: '2em', top: "1em", position: "absolute"}}>Logout</Button>
      </header>
      <div style={{height: "calc(100vh - 100px)", overflow: "hidden"}}>
        {!loading && <ControlledTabs token={token} loading={loading}/>}
      </div>
    </div>
  )  
  return frontPage
}

