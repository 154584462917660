import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from "axios";
import useToken from '../../../App/useToken';




// id: Optional[PydanticObjectId] = Field(None, alias="_id")
// name: str
// hp: str
// customerId: str
// dateAdded: Optional[datetime]
// dateUpdated: Optional[datetime]
// status: Optional[str]

//  "/insert_new_drone"

export function NewBatteryForm({batteryStatusList, customers, setAddNew, getBatteries, setUpdate, update}) {
  const {token} = useToken()
  const [validated, setValidated] = useState(false);
  const [customerIdList, setCustomerIdList] = useState([])
  const [serialNumber, setSerialNumber] = useState("")
  const [batteryCustomerId, setBatteryCustomerId] = useState("101010")
  const [batteryStatus, setBatteryStatus] = useState("Healthy")
  const [location, setLocation] = useState("DF")

  const formRef = useRef(null)
  const handleSubmitNewBattery = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        
        const data = {
            'token': token,
            'battery': {
                'serialNumber': serialNumber,
                'customerId': batteryCustomerId,
                'status': batteryStatus,
                'location': location
            }
        }
        var config = {
            method: 'post',
            url: "api/insert_new_battery",
            data: data
        };
        console.log(config, data)
        axios(config).then(response => {
            console.log(response.data)
            getBatteries()
            setUpdate(!update)
        }).catch(error => {
            // console.log('error', error)
        })

        event.preventDefault();
        event.stopPropagation();
    }
    // console.log(event)
    setValidated(true);
  };
  
  useEffect(()=> {
    if(customers && Array.isArray(customers)){
        let selectCustomerList = [<option value="101010">DF Dev</option>]
        customers.forEach(customer => {
            if(customer.customerId !== "101010"){
                selectCustomerList.push(<option value={customer.customerId}>{customer.name}</option>)
            }
        })
        setCustomerIdList(selectCustomerList)
        // console.log(selectCustomerList)
        // console.log(formRef)
    }
  }, [])

  return (
    <div>
        <Form ref={formRef} noValidate validated={validated} onSubmit={handleSubmitNewBattery}>
            <Form.Group md="4" controlId="validationSerialNumber">
            <Form.Label>Serial number</Form.Label>   
                <InputGroup hasValidation>
                    {/* <InputGroup.Text id="inputGroupPrepend">df-drone-</InputGroup.Text> */}
                    <Form.Control
                        required
                        type="text"
                        placeholder="Battery serial number"
                        aria-describedby="basic-addon1"
                        defaultValue=""
                        onChange={(e)=> setSerialNumber(e.target.value)}
                    />
                </InputGroup>
            </Form.Group>
            <Form.Group md="4" controlId="validationBatteryCustomerId">
            <Form.Label>CustomerId</Form.Label>
            <InputGroup hasValidation>
                <Form.Select onChange={(e)=> setBatteryCustomerId(e.target.value)} defaultValue='101010' aria-label="battery customerId">
                    {
                        customerIdList
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    Select customerId
                </Form.Control.Feedback>
            </InputGroup>
            </Form.Group>
            <Form.Group md="4" controlId="validationBatteryStatus">
            <Form.Label>Status</Form.Label>
            <InputGroup hasValidation>
                <Form.Select onChange={(e)=> setBatteryStatus(e.target.value)} defaultValue='Healthy' aria-label="battery status">
                    {
                        batteryStatusList
                    }
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    Select status
                </Form.Control.Feedback>
            </InputGroup>
            </Form.Group>
            <Form.Group md="4" controlId="validationCustomBatteryLocation">
            <Form.Label>Location</Form.Label>
            <Form.Control
                type="text"
                placeholder="Location"
                defaultValue=""
                onChange={(e) => setLocation(e.target.value)}
            />
            </Form.Group>
            <Button variant="secondary" onClick={() => setAddNew(false)}>Cancel</Button>
            <Button variant="primary" className='df-button' type="submit">Submit new battery</Button>
        </Form>
    </div>
  );
}