import React, {useState,useEffect}  from 'react';
import PropTypes from 'prop-types'
import { Card, ListGroup, Dropdown, ButtonGroup, Button, Modal } from 'react-bootstrap'
import { Element } from "react-scroll"

export default function ReportSelectionList(props) {
    const [bagfiles, setBagfiles] = useState([])
    
    const handleClick = (item) => {
        props.setSelectedBagfiles(item)
    }
    useEffect(() => {
        console.log(props.selectedBagfiles)
    }, [props.selectedBagfiles])
    useEffect(() => {
        if(props.bagData) {
            setBagfiles(props.bagData.map(item => {
                if(props.ctx?.filterBagData === 'notReviewed'){
                    if(item.isReviewed){
                        return
                    }
                } else if(props.ctx?.filterBagData === 'reviewed'){
                    if(!item.isReviewed){
                        return
                    }
                } else if(!String(item.processState).includes("Finished")){
                    return
                }
                // const status = Helper.getStatus(item.processState)
                
                // String(item.processState).includes('Failed') ? " - "+item.processState :
                //   item.processState !== "Finished" ? " - "+ item.processState : ""
                const hasAlias = item.alias && item.alias !== ""
                const name = hasAlias ?  item.alias : item.name
                return (
                    <Dropdown style={{marginBottom: "1em", lineBreak: "anywhere" }} as={ButtonGroup}>
                        <ListGroup.Item
                        action
                        variant={props?.selectedBagfiles?.some(i => i._id === item._id) ? "info" : ""}
                        active={props?.selectedBagfiles?.some(i => i._id === item._id)}
                        eventKey={item._id} 
                        key={item._id} 
                        onClick={(e) => {
                            handleClick(item) 
                            e.preventDefault()
                            e.stopPropagation()}}
                        disabled={!item.processState.includes("Finished")}
                        >
                            {name}
                        </ListGroup.Item>
                        <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

                            <Dropdown.Menu variant="dark">
                                {/* {customer === '101010' && <Dropdown.Item disabled={!(String(item.processState).includes('Failed') || item.processState.includes("Finished"))} eventKey={`download-${item._id}`} onClick={() => {openDownloadDialog(item)}}>
                                    Download pcd...
                                </Dropdown.Item>} */}
                            
                                {/* <Dropdown.Divider /> */}
                                
                            </Dropdown.Menu>
                    </Dropdown>
                    )
            }))
        }
    }, [props.bagData, props.selectedBagfiles])
    const listView = bagfiles.length < 1 ? <h3>loading...</h3> : bagfiles
    return (
        <div name="forestDetailsItems" className="element" id="containerElement" style={{
            position: 'relative',
            height: "100%",
            width: "95%",
            overflowY: 'auto',
            overflowX: 'Hidden',
            marginBottom: '1.5em',
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
            }}>
            <Card 
                bg={"dark"}
                key={"ForestDetailsItems"}
                text={"white"}
                style={{minWidth: "200px", height: "100%", margin: "1em", border: "3px outset #0f5132"}}
                className="mb-3">
                    <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>Forest scans</Card.Header>
                    <Element name="forestDetailsItems" className="element" id="containerElement" style={{
                    position: "relative",
                    height: "100%",
                    width: "100%",
                    overflowY: "auto",
                    overflowX: "Hidden",
                    marginBottom: "1.5em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                    }}>
                    <ListGroup style={{width: "95%", padding: "1em", position: "absolute"}}>
                        { listView } 
                    </ListGroup>
                </Element>
            </Card>
             
        </div>
    )
}

ReportSelectionList.propTypes = {
    bagData: PropTypes.array,
    selectedBagfiles: PropTypes.object,
    setSelectedBagfiles: PropTypes.func.isRequired,
    ctx: PropTypes.object.isRequired
}