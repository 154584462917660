import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'react-bootstrap'
import useToken from '../App/useToken'
import axios from 'axios'

export default function ChangeAliasDialogPopup(props) {
  const { token } = useToken()

  const name = props.itemToChangeAlias ? props.itemToChangeAlias.name : ''
  const changeAlias = async (alias) => {
    // console.log(props.itemToChangeAlias._id)
    // var formData = new FormData();
    // formData.append('token', token)
    // formData.append('alias', alias)
    // formData.append('id', props.itemToChangeAlias._id)
    var config = {
      method: "post",
      url: "api/update_dronefile",
      data: {'token': token, '_id': props.itemToChangeAlias._id, 'alias': alias}
    };
    axios(config)
    .then(response => 
     {    
      // console.log(response)
    })
    .catch(error => {
      console.log("error", error)
    })
  } 
  const [alias, setAlias] = useState("")

  const handleSubmit = async (e) => {
    // console.log(alias)
    e.preventDefault()
    const res = await changeAlias(alias)
    // console.log(res)
    props.setChangeAliasDialogShow(false)
  } 
  return (
    <Modal
      show={props.changeAliasDialogShow}
      onHide={() => props.setChangeAliasDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change alias for {name} 
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>New alias</Form.Label>
            <Form.Control placeholder="Enter new alias" onChange={e => setAlias(e.target.value)}/>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setChangeAliasDialogShow(false)}>Cancel</Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

ChangeAliasDialogPopup.propTypes = {
  itemToChangeAlias: PropTypes.object.isRequired,
  changeAliasDialogShow: PropTypes.bool.isRequired,
  setChangeAliasDialogShow: PropTypes.func.isRequired,
}
