import React, {useEffect, useState , useMemo} from 'react'
import { ProgressBar, Button, Form, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'
import { styles } from './styles';
import axios from "axios";
import useToken from '../../App/useToken';


const chunkSize = 1048576 * 5;//its 5MB, increase the number measure in mb


export default function MeasurementDropZone(props) {
  const {token} = useToken()  
  const handleClick = () => {
    if(data){
        console.log(data)
        var config = {
            method: 'post',
            url: "file-handler/update_measurement",
            data: {
                token: token,
                data: data
            }
        };
        axios(config).then(response => {
            console.log(response)
        }).catch(error => {
          console.log('error', error)
        })   
    }
  }
  const [data, setData] = useState(null)
  const {
      acceptedFiles,
      open,
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject, 
             } = useDropzone({
          maxFiles: 1,
          onDropAccepted: e => {
            // console.log(e)
            
          },
          // onDrop: acceptedFiles => {
          //     setFileAccepted(true)
          //     getFileContext(acceptedFiles)},
          onDropRejected: e => {
            // console.log(e)
            
            }  
      });

    const style = useMemo(() => ({
            ...styles.baseStyle,
            ...(isFocused ? styles.focusedStyle : {}),
            ...(isDragAccept ? styles.acceptStyle : {}),
            ...(isDragReject ? styles.rejectStyle : {})
          }), [
            isFocused,
            isDragReject,
            isDragAccept
          ]);  

    const files = acceptedFiles.map(file => {
        const reader = new FileReader()
        reader.onload = async (e) => { 
            const text = e.target.result
            const d = JSON.parse(text)
            if(props.selectedItem){
                d.info = props.selectedItem
            }
            
            setData(d)
          };
        reader.readAsText(file)
       return ( 
        <>
        <h4>{'File to upload'}</h4>
        
        <ul>
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
            <li key={file.lastModified}>
                last modified: {new Date(file.lastModified).toLocaleDateString()}
            </li>
            <p>{}</p>
      </ul>
      </>)
    
    });
    
    
    
    return (  
            <Modal
                show={props.show}
                onHide={() => props.onHide(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="container">
                        <div {...getRootProps({style})} onClick={(e) => {console.log(e); open()}}>
                            <input {...getInputProps()} />
                        </div>
                    </div>
                    <aside>
                      <ul>{files}</ul>
                      <p style={{color: 'orangered'}}></p>
                    </aside>
                   
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleClick()}> OK</Button>
                    <Button onClick={() => props.onHide(false)}>cancel</Button>
                </Modal.Footer>
            </Modal>
    )
  }

MeasurementDropZone.propTypes = {
    onHide: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    selectedItem: PropTypes.object.isRequired,
}