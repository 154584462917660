import React from 'react'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Card, ListGroup, Dropdown, ButtonGroup } from 'react-bootstrap'

export const listgroupForestDetails = [
(   
    <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'1'} 
          key={'1'} 
          onClick={() => null}>
          This is a very long name, wow, such long, can it be this long
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-1`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-1`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'2'} 
          key={'2'} 
          onClick={() => null}>
          {'Shorter name'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-2`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-2`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'3'} 
          key={'3'} 
          onClick={() => null}>
          {'This is a very long name, wow, such long, can it be this long'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-3`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-3`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'4'} 
          key={'4'} 
          onClick={() => null}>
          {'Shorter name'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-4`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-4`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'5'} 
          key={'5'} 
          onClick={() => null}>
          {'This is a very long name, wow, such long, can it be this long'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-5`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-5`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'6'} 
          key={'6'} 
          onClick={() => null}>
          {'Shorter name'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-6`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-6`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'7'} 
          key={'7'} 
          onClick={() => null}>
          {'This is a very long name, wow, such long, can it be this long'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-7`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-7`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> ),
(   <Dropdown as={ButtonGroup} style={{marginBottom: "1em"}}>
      <ListGroup.Item
          
          action
          active={false}
          eventKey={'8'} 
          key={'8'} 
          onClick={() => null}>
          {'Shorter name'}
      </ListGroup.Item>
      <Dropdown.Toggle split variant="outline-secondary" id="dropdown-button-drop-end"/>

        <Dropdown.Menu variant="dark">
            <Dropdown.Item eventKey={`ViewIndividualTrees-8`}>View individual trees</Dropdown.Item>
            <Dropdown.Item eventKey={`View3D-8`}>View in 3D</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown> )]

export const testForestData = {
  DBH: [
      ["DBH", "0.00"], ['DBH', 1.22], ['DBH', 1.43], ['DBH', 1.52], ['DBH', 1.52], ['DBH', 1.00], ['DBH', 1.22]
  ], 
  volume: [
      ["Volume", "0.00"], ['Volume', 1.22], ['Volume', 1.43], ['Volume', 1.52], ['Volume', 1.52], ['Volume', 1.00], ['Volume', 1.22]
  ], 
  height: [
      ["Height", "0.00"], ['Height', 22.22], ['Height', 20.43], ['Height', 22.52], ['Height', 23.52], ['Height', 21.00], ['Height', 22.22]
  ], sumDBH: 6 , sumVol: 6, sumHeight: 100,
  Full: [
    {
        "ID": 1,
        "x": -78.221,
        "y": 18.304,
        "DBH": 0.288,
        "volume": 1.200,
        "height": 18.409
    },
    {
        "ID": 2,
        "x": -76.843,
        "y": 12.612,
        "DBH": 0.654,
        "volume": 5.1,
        "height": 15.27
    },
    {
        "ID": 3,
        "x": -74.39,
        "y": 15.592,
        "DBH": 0.3127,
        "volume": 1.336,
        "height": 17.4
    },
    {
        "ID": 4,
        "x": -74.38,
        "y": 10.507,
        "DBH": 0.56,
        "volume": 5.09,
        "height": 20.57
    },
    {
        "ID": 5,
        "x": -74.355,
        "y": 6.713,
        "DBH": 0.3768,
        "volume": 2.151,
        "height": 19.29
    },
    {
        "ID": 6,
        "x": -73.781,
        "y": 21.07,
        "DBH": 0.2389,
        "volume": 0.683,
        "height": 15.239
    },
    {
        "ID": 8,
        "x": -72.245,
        "y": 13.746,
        "DBH": 0.3314,
        "volume": 1.423,
        "height": 16.49
    },
    {
        "ID": 9,
        "x": -72.24,
        "y": 18.96,
        "DBH": 0.2326,
        "volume": 0.583,
        "height": 13.73
    },
    {
        "ID": 10,
        "x": -71.027,
        "y": 7.190,
        "DBH": 0.2130,
        "volume": 0.553,
        "height": 15.54
    },
    {
        "ID": 11,
        "x": -67.594,
        "y": 11.611,
        "DBH": -0.1772,
        "volume": 0.479,
        "height": 19.439
    },
    {
        "ID": 12,
        "x": -68.508,
        "y": 17.82,
        "DBH": 0.254,
        "volume": 0.972,
        "height": 19.11
    },
    {
        "ID": 13,
        "x": -68.145,
        "y": 7.0690,
        "DBH": 0.2400,
        "volume": 0.94,
        "height": 20.92
    },
    {
        "ID": 14,
        "x": -66.943,
        "y": 4.516,
        "DBH": 0.3293,
        "volume": 1.552,
        "height": 18.23
    },
    {
        "ID": 15,
        "x": -64.364,
        "y": -6.441,
        "DBH": 0.3266,
        "volume": 1.59,
        "height": 19.02
    },
    {
        "ID": 16,
        "x": -64.416,
        "y": 5.101,
        "DBH": 0.46,
        "volume": 3.35,
        "height": 19.45
    },
    {
        "ID": 17,
        "x": -61.024,
        "y": 8.702,
        "DBH": 0.342,
        "volume": 1.712,
        "height": 18.56
    },
    {
        "ID": 19,
        "x": -62.058,
        "y": 31.63,
        "DBH": 0.493,
        "volume": 3.66,
        "height": 19.19
    },
    {
        "ID": 20,
        "x": -62.415,
        "y": 18.091,
        "DBH": 0.3398,
        "volume": 1.39,
        "height": 15.33
    },
    {
        "ID": 21,
        "x": -59.902,
        "y": 1.294,
        "DBH": 0.2642,
        "volume": 1.155,
        "height": 21.06
    },
    {
        "ID": 22,
        "x": -61.4852,
        "y": 25.497,
        "DBH": 0.25,
        "volume": 0.883,
        "height": 16.790
    },
    {
        "ID": 23,
        "x": -58.9922,
        "y": -9.387,
        "DBH": 0.2610,
        "volume": 1.090,
        "height": 20.39
    },
    {
        "ID": 24,
        "x": -59.827,
        "y": 28.24,
        "DBH": 0.299,
        "volume": 1.325,
        "height": 18.770
    },
    {
        "ID": 25,
        "x": -56.199,
        "y": -14.384,
        "DBH": 0.473,
        "volume": 3.45,
        "height": 19.630
    },
    {
        "ID": 26,
        "x": -57.491,
        "y": 33.606,
        "DBH": 0.21,
        "volume": 0.671,
        "height": 18.1
    },
    {
        "ID": 27,
        "x": -57.14,
        "y": 24.662,
        "DBH": 0.2731,
        "volume": 0.802,
        "height": 13.690
    },
    {
        "ID": 28,
        "x": -56.005,
        "y": 23.00,
        "DBH": 0.1653,
        "volume": 0.3410,
        "height": 15.880
    },
    {
        "ID": 29,
        "x": -55.820,
        "y": 12.121,
        "DBH": 0.2413,
        "volume": 0.988,
        "height": 21.619
    },
    {
        "ID": 31,
        "x": -54.8795,
        "y": 38.82,
        "DBH": 0.3238,
        "volume": 1.720,
        "height": 20.89
    },
    {
        "ID": 32,
        "x": -54.7518,
        "y": -4.4175,
        "DBH": 0.521,
        "volume": 3.71,
        "height": 17.380
    },
    {
        "ID": 33,
        "x": -54.8873,
        "y": 0.9274,
        "DBH": 0.1663,
        "volume": 0.3186,
        "height": 14.66
    },
    {
        "ID": 34,
        "x": -51.7532,
        "y": -13.146,
        "DBH": 0.328,
        "volume": 1.74,
        "height": 20.57
    },
    {
        "ID": 35,
        "x": -54.24,
        "y": 33.99,
        "DBH": 0.238,
        "volume": 0.831,
        "height": 18.54
    },
    {
        "ID": 36,
        "x": -52.024,
        "y": 36.50,
        "DBH": 0.274,
        "volume": 1.214,
        "height": 20.45
    },
    {
        "ID": 38,
        "x": -52.661,
        "y": -17.118,
        "DBH": 0.3492,
        "volume": 1.723,
        "height": 17.990
    },
    {
        "ID": 39,
        "x": -49.064,
        "y": 6.382,
        "DBH": 0.29,
        "volume": 1.49,
        "height": 21.26
    },
    {
        "ID": 40,
        "x": -49.9078,
        "y": -26.395,
        "DBH": 0.520,
        "volume": 3.85,
        "height": 18.11
    },
    {
        "ID": 41,
        "x": -47.6523,
        "y": 40.488,
        "DBH": 0.0781,
        "volume": 0.09,
        "height": 20.8
    },
    {
        "ID": 42,
        "x": -50.287,
        "y": 35.09,
        "DBH": 0.21,
        "volume": 0.736,
        "height": 19.39
    },
    {
        "ID": 43,
        "x": -47.8076,
        "y": -21.719,
        "DBH": 0.965,
        "volume": 14.03,
        "height": 19.18
    },
    {
        "ID": 44,
        "x": -48.7773,
        "y": -13.812,
        "DBH": 0.303,
        "volume": 1.433,
        "height": 19.78
    },
    {
        "ID": 45,
        "x": -46.932,
        "y": 16.837,
        "DBH": 0.2632,
        "volume": 1.001,
        "height": 18.39
    },
    {
        "ID": 46,
        "x": -46.2348,
        "y": -6.732,
        "DBH": 0.1933,
        "volume": 0.546,
        "height": 18.6
    },
    {
        "ID": 47,
        "x": -43.461,
        "y": 9.80,
        "DBH": 0.60,
        "volume": 5.56,
        "height": 19.490
    },
    {
        "ID": 48,
        "x": -45.157,
        "y": -32.34,
        "DBH": 0.1326,
        "volume": 0.2240,
        "height": 16.200
    },
    {
        "ID": 49,
        "x": -46.117,
        "y": -25.20,
        "DBH": 0.273,
        "volume": 0.883,
        "height": 15.08
    },
    {
        "ID": 50,
        "x": -45.829,
        "y": 18.82,
        "DBH": 0.2707,
        "volume": 1.080,
        "height": 18.77
    },
    {
        "ID": 52,
        "x": -43.997,
        "y": -0.6452,
        "DBH": 0.498,
        "volume": 2.92,
        "height": 14.97
    },
    {
        "ID": 53,
        "x": -43.9992,
        "y": 37.361,
        "DBH": 0.206,
        "volume": 0.715,
        "height": 21.36
    },
    {
        "ID": 54,
        "x": -44.384,
        "y": 21.868,
        "DBH": 0.2751,
        "volume": 1.230,
        "height": 20.69
    },
    {
        "ID": 56,
        "x": -42.344,
        "y": 15.117,
        "DBH": 0.55,
        "volume": 4.159,
        "height": 17.07
    },
    {
        "ID": 57,
        "x": -42.363,
        "y": 3.9466,
        "DBH": 0.228,
        "volume": 0.759,
        "height": 18.57
    },
    {
        "ID": 58,
        "x": -41.921,
        "y": 45.83,
        "DBH": 0.640,
        "volume": 6.16,
        "height": 19.11
    },
    {
        "ID": 59,
        "x": -38.935,
        "y": -25.11,
        "DBH": 0.3381,
        "volume": 1.594,
        "height": 17.759
    },
    {
        "ID": 60,
        "x": -42.4909,
        "y": 27.38,
        "DBH": 0.2529,
        "volume": 0.932,
        "height": 18.560
    },
    {
        "ID": 61,
        "x": -42.185,
        "y": -18.96,
        "DBH": 0.2476,
        "volume": 1.023,
        "height": 21.25
    },
    {
        "ID": 62,
        "x": -41.216,
        "y": 32.63,
        "DBH": 0.591,
        "volume": 0.867,
        "height": 3.16
    },
    {
        "ID": 63,
        "x": -38.7752,
        "y": 26.617,
        "DBH": 0.2571,
        "volume": 1.011,
        "height": 19.48
    },
    {
        "ID": 64,
        "x": -36.942,
        "y": -14.871,
        "DBH": 0.1979,
        "volume": 0.599,
        "height": 19.5
    },
    {
        "ID": 65,
        "x": -40.811,
        "y": 42.984,
        "DBH": 0.2927,
        "volume": 1.284,
        "height": 19.080
    },
    {
        "ID": 66,
        "x": -40.135,
        "y": 6.388,
        "DBH": 0.2230,
        "volume": 0.748,
        "height": 19.16
    },
    {
        "ID": 67,
        "x": -40.329,
        "y": 13.29,
        "DBH": 0.3610,
        "volume": 2.117,
        "height": 20.68
    },
    {
        "ID": 68,
        "x": -40.0613,
        "y": 30.044,
        "DBH": 0.638,
        "volume": 0.86,
        "height": 2.689
    },
    {
        "ID": 69,
        "x": -37.420,
        "y": 38.62,
        "DBH": -0.0634,
        "volume": 0.0655,
        "height": 20.7
    },
    {
        "ID": 70,
        "x": -39.0052,
        "y": 15.236,
        "DBH": 0.2492,
        "volume": 0.906,
        "height": 18.59
    },
    {
        "ID": 71,
        "x": -37.498,
        "y": 21.826,
        "DBH": 0.697,
        "volume": 7.11,
        "height": 18.59
    },
    {
        "ID": 72,
        "x": -37.092,
        "y": -19.051,
        "DBH": 0.2137,
        "volume": 0.63,
        "height": 17.69
    },
    {
        "ID": 73,
        "x": -36.360,
        "y": 10.213,
        "DBH": 0.1977,
        "volume": 0.607,
        "height": 19.78
    },
    {
        "ID": 75,
        "x": -35.536,
        "y": 0.675,
        "DBH": 0.1613,
        "volume": 0.306,
        "height": 15.0
    },
    {
        "ID": 76,
        "x": -35.1493,
        "y": 15.187,
        "DBH": 0.2474,
        "volume": 0.869,
        "height": 18.08
    },
    {
        "ID": 77,
        "x": -35.179,
        "y": 29.834,
        "DBH": 0.3123,
        "volume": 1.441,
        "height": 18.810
    },
    {
        "ID": 78,
        "x": -35.088,
        "y": 3.635,
        "DBH": 0.1661,
        "volume": 0.323,
        "height": 14.920
    },
    {
        "ID": 79,
        "x": -34.65,
        "y": 33.28,
        "DBH": 0.2282,
        "volume": 0.1227,
        "height": 3.0
    },
    {
        "ID": 80,
        "x": -35.07,
        "y": 39.35,
        "DBH": 0.1963,
        "volume": 0.526,
        "height": 17.4
    },
    {
        "ID": 81,
        "x": -33.8288,
        "y": 49.28,
        "DBH": 0.375,
        "volume": 1.698,
        "height": 15.329
    },
    {
        "ID": 82,
        "x": -32.9720,
        "y": -41.86,
        "DBH": 0.328,
        "volume": 1.54,
        "height": 18.27
    },
    {
        "ID": 83,
        "x": -32.517,
        "y": 25.257,
        "DBH": 0.2304,
        "volume": 0.785,
        "height": 18.830
    },
    {
        "ID": 84,
        "x": -31.8646,
        "y": 8.956,
        "DBH": 0.2773,
        "volume": 0.799,
        "height": 13.23
    },
    {
        "ID": 85,
        "x": -33.101,
        "y": -23.380,
        "DBH": 0.3555,
        "volume": 1.930,
        "height": 19.44
    },
    {
        "ID": 87,
        "x": -31.1709,
        "y": 44.144,
        "DBH": 0.169,
        "volume": 0.451,
        "height": 20.080
    },
    {
        "ID": 88,
        "x": -31.306,
        "y": -29.990,
        "DBH": 0.3008,
        "volume": 1.410,
        "height": 19.84
    },
    {
        "ID": 90,
        "x": -31.989,
        "y": -35.06,
        "DBH": 0.147,
        "volume": 0.2142,
        "height": 12.54
    },
    {
        "ID": 91,
        "x": -30.9928,
        "y": 3.0560,
        "DBH": 0.394,
        "volume": 1.248,
        "height": 10.190
    },
    {
        "ID": 92,
        "x": -26.736,
        "y": 50.433,
        "DBH": 0.278,
        "volume": 1.14,
        "height": 18.92
    },
    {
        "ID": 93,
        "x": -29.1902,
        "y": -22.027,
        "DBH": 0.2429,
        "volume": 0.865,
        "height": 18.67
    },
    {
        "ID": 94,
        "x": -29.6641,
        "y": -35.86,
        "DBH": 0.333,
        "volume": 1.623,
        "height": 18.54
    },
    {
        "ID": 95,
        "x": -29.2904,
        "y": 24.172,
        "DBH": 0.2200,
        "volume": 0.64,
        "height": 17.090
    },
    {
        "ID": 96,
        "x": -28.85,
        "y": -32.472,
        "DBH": 0.190,
        "volume": 0.453,
        "height": 15.86
    },
    {
        "ID": 97,
        "x": -28.1085,
        "y": 26.,
        "DBH": 0.2307,
        "volume": 0.734,
        "height": 17.560
    },
    {
        "ID": 98,
        "x": -27.2907,
        "y": 2.115,
        "DBH": 0.477,
        "volume": 2.57,
        "height": 14.38
    },
    {
        "ID": 99,
        "x": -26.4919,
        "y": 10.030,
        "DBH": 0.3206,
        "volume": 1.615,
        "height": 20.0
    },
    {
        "ID": 100,
        "x": -22.49,
        "y": -39.38,
        "DBH": 0.1498,
        "volume": 0.3172,
        "height": 17.980
    },
    {
        "ID": 101,
        "x": -25.2473,
        "y": -48.0,
        "DBH": 0.3799,
        "volume": 1.868,
        "height": 16.48
    },
    {
        "ID": 102,
        "x": -25.321,
        "y": 31.3,
        "DBH": 0.998,
        "volume": 13.54,
        "height": 17.310
    },
    {
        "ID": 103,
        "x": -25.8970,
        "y": 38.90,
        "DBH": 0.274,
        "volume": 1.02,
        "height": 17.35
    },
    {
        "ID": 105,
        "x": -25.731,
        "y": 41.16,
        "DBH": 0.47,
        "volume": 0.372,
        "height": 2.1
    },
    {
        "ID": 106,
        "x": -25.4367,
        "y": -30.540,
        "DBH": 0.2389,
        "volume": 0.882,
        "height": 19.689
    },
    {
        "ID": 107,
        "x": -23.640,
        "y": 44.053,
        "DBH": 0.598,
        "volume": 5.22,
        "height": 18.580
    },
    {
        "ID": 108,
        "x": -24.332,
        "y": 54.16,
        "DBH": 0.672,
        "volume": 2.774,
        "height": 7.800
    },
    {
        "ID": 109,
        "x": -24.2399,
        "y": -22.28,
        "DBH": 0.2450,
        "volume": 0.607,
        "height": 12.89
    },
    {
        "ID": 110,
        "x": -23.3934,
        "y": -43.29,
        "DBH": 0.130,
        "volume": 0.1681,
        "height": 12.59
    },
    {
        "ID": 111,
        "x": -21.1551,
        "y": -23.540,
        "DBH": 0.1475,
        "volume": 0.256,
        "height": 15.010
    },
    {
        "ID": 112,
        "x": -22.8945,
        "y": -35.89,
        "DBH": 0.2816,
        "volume": 1.118,
        "height": 17.96
    },
    {
        "ID": 113,
        "x": -21.9376,
        "y": -29.731,
        "DBH": 0.2053,
        "volume": 0.58,
        "height": 17.53
    },
    {
        "ID": 114,
        "x": -21.6082,
        "y": -13.949,
        "DBH": 0.2066,
        "volume": 0.533,
        "height": 15.9
    },
    {
        "ID": 116,
        "x": -18.745,
        "y": -46.91,
        "DBH": 0.868,
        "volume": 10.59,
        "height": 17.9
    },
    {
        "ID": 117,
        "x": -20.6672,
        "y": 8.418,
        "DBH": 0.257,
        "volume": 0.873,
        "height": 16.77
    },
    {
        "ID": 118,
        "x": -21.20,
        "y": 29.177,
        "DBH": 0.247,
        "volume": 0.719,
        "height": 15.0
    },
    {
        "ID": 119,
        "x": -20.2582,
        "y": 53.755,
        "DBH": 0.2028,
        "volume": 0.486,
        "height": 15.07
    },
    {
        "ID": 120,
        "x": -20.770,
        "y": 18.9,
        "DBH": 0.1505,
        "volume": 0.2400,
        "height": 13.489
    },
    {
        "ID": 121,
        "x": -20.3559,
        "y": 50.683,
        "DBH": 0.1855,
        "volume": 0.478,
        "height": 17.68
    },
    {
        "ID": 123,
        "x": -17.411,
        "y": 48.148,
        "DBH": 0.2175,
        "volume": 0.635,
        "height": 17.1
    },
    {
        "ID": 124,
        "x": -18.007,
        "y": -54.153,
        "DBH": 0.212,
        "volume": 0.635,
        "height": 17.990
    },
    {
        "ID": 125,
        "x": -18.5072,
        "y": 32.148,
        "DBH": 0.2977,
        "volume": 1.078,
        "height": 15.50
    },
    {
        "ID": 126,
        "x": -18.5902,
        "y": 25.70,
        "DBH": 0.2498,
        "volume": 0.852,
        "height": 17.389
    },
    {
        "ID": 127,
        "x": -17.153,
        "y": -11.7,
        "DBH": 0.253,
        "volume": 0.932,
        "height": 18.509
    },
    {
        "ID": 128,
        "x": -17.700,
        "y": 10.963,
        "DBH": 1.442,
        "volume": 25.88,
        "height": 15.84
    },
    {
        "ID": 129,
        "x": -17.2415,
        "y": 18.93,
        "DBH": 0.4518,
        "volume": 2.40,
        "height": 14.989
    },
    {
        "ID": 130,
        "x": -15.1984,
        "y": -38.2,
        "DBH": 1.058,
        "volume": 16.87,
        "height": 19.19
    },
    {
        "ID": 131,
        "x": -15.14,
        "y": 52.393,
        "DBH": 0.2052,
        "volume": 0.594,
        "height": 17.96
    },
    {
        "ID": 132,
        "x": -16.2689,
        "y": -50.08,
        "DBH": 0.40,
        "volume": 2.41,
        "height": 19.15
    },
    {
        "ID": 133,
        "x": -14.1885,
        "y": -30.825,
        "DBH": 0.2951,
        "volume": 1.20,
        "height": 17.66
    },
    {
        "ID": 134,
        "x": -14.9693,
        "y": 56.43,
        "DBH": 0.279,
        "volume": 0.968,
        "height": 15.830
    },
    {
        "ID": 136,
        "x": -13.1003,
        "y": 37.67,
        "DBH": 0.39,
        "volume": 1.446,
        "height": 11.65
    },
    {
        "ID": 137,
        "x": -15.1091,
        "y": -54.31,
        "DBH": 0.344,
        "volume": 1.425,
        "height": 15.26
    },
    {
        "ID": 138,
        "x": -14.025,
        "y": 25.874,
        "DBH": 0.3171,
        "volume": 1.2,
        "height": 16.060
    },
    {
        "ID": 139,
        "x": -14.2060,
        "y": 28.94,
        "DBH": 0.165,
        "volume": 0.2963,
        "height": 13.790
    },
    {
        "ID": 140,
        "x": -13.3872,
        "y": -50.20,
        "DBH": 0.2232,
        "volume": 0.667,
        "height": 17.05
    },
    {
        "ID": 141,
        "x": -10.3896,
        "y": -53.08,
        "DBH": -0.1603,
        "volume": 0.3993,
        "height": 19.770
    },
    {
        "ID": 142,
        "x": -13.2039,
        "y": 41.111,
        "DBH": 0.464,
        "volume": 2.6,
        "height": 15.55
    },
    {
        "ID": 143,
        "x": -9.7029,
        "y": -34.529,
        "DBH": 0.541,
        "volume": 4.92,
        "height": 21.380
    },
    {
        "ID": 144,
        "x": -11.293,
        "y": 29.97,
        "DBH": 0.274,
        "volume": 1.060,
        "height": 17.96
    }]
}