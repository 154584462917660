import React, {useState,useEffect,useRef}  from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types'

import ItemList from './ItemList/ItemList';
import SelectedInfoView from './SelectedInfoView/SelectedInfoView';
import { NewCustomerForm } from './SelectedInfoView/CustomersView/newCustomerForm';
import SelectedCustomerInfo from './SelectedInfoView/CustomersView/SelectedICustomerInfoView';
import SelectedDroneInfo from './SelectedInfoView/DronesView/SelectedDroneInfoView';


export default function GeneralView(props) {
    const addNewText = props.type === 'customer' && 'Customers'
    const [addNew, setAddNew] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const newSelectedItem = (item) => {
        setSelectedItem(item)
    }
    const handleNewType = () => {
        setAddNew(true)
    }
    return (
        <div style={{
            display: 'flex',
            height: "100%",
            width: "100%"
          }}>
            <div style= {{width: "30%", height: "100%"}}>
                <ItemList items={props.items} selectItem={newSelectedItem} type={props.type}/>
                {/* {props.type === 'customer' && <Button onClick={addNew()}>{addNewText}</Button>} */}
            </div>
            <div style={{width: "65%", height: "100%"}}>
                {props.type === "customer" && <SelectedCustomerInfo item={selectedItem} itemType={props.type} update={props.update} setUpdate={props.setUpdate}/>}
                {props.type === "drone" && <SelectedDroneInfo drone={selectedItem} itemType={props.type} update={props.update} setUpdate={props.setUpdate}/>}
                {/* <SelectedInfoView item={selectedItem} itemType={props.type}/> */}
                {/* {addNew === 'customer' && <NewCustomerForm/> } */}
            </div>
             {/* List view left, Selection, edit, delete.
                Selected item view right
                Edit, add new bottom
                EditView (instead of Selected view)
             */
             
             }
        </div>
    )
}

GeneralView.protoTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    update: PropTypes.number.isRequired,
    setUpdate: PropTypes.func.isRequired,
}