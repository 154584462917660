import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import axios from "axios";
import useToken from '../../../../App/useToken';

const SelectedDroneInfo = ({ drone, update, setUpdate }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [customerOptions, setCustomersOptions] = useState([])
  const handleShowForm = () => {
    setShowForm(true);
  };
  const { token } = useToken()
  const handleUpdate = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleConfirmUpdate = () => {
    // Update the drone's CustomerId here
    // This is where you would typically make an API call to update the database
    console.log('new customerId, ', selectedCustomerId);  // Update the drone's CustomerId
    console.log('drone, ', drone);
    if(selectedCustomerId && selectedCustomerId !== drone.customerId){
        const data = {
            token: token,
            drone: {
                name: drone.name,
                customerId: selectedCustomerId,
            }
        }
        var config = {
            method: 'post',
            url: "api/update_drone",
            data: data
        };
        axios(config).then(response => {
            console.log( response.data)
            setUpdate(update+1)
        }).catch(error => {
            console.log('error', error)
        })
            console.log("has value")
        }  // Update the drone's CustomerId
    setShowModal(false);
    setShowForm(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    var formData = new FormData();
    formData.append('token', token);
    var config = {
        method: 'post',
        url: "api/get_customers",
        data: formData
    };
    axios(config).then(response => {
        // console.log( response.data)
        var customersList = [<option>Select customer to update drone</option>] 
        response.data.customers.forEach(element => {
           customersList.push(<option value={element.customerId}>{element.name}</option>) 
        });
        setCustomersOptions(customersList)
    }).catch(error => {
        console.log('error', error)
    })
  },[])
  useEffect(()=>{
    
  },[update])
  return (
    <div style={{
        display: "flex",
        height: "100%",
        width:"100%",
      }}>
        <Card 
            bg={"dark"}
            key={"AdminSelectedItem"}
            text={"white"}
            style={{minWidth: "95%", height: "90%", margin: "1em", border: "3px outset #0f5132"}}
            className="mb-3">
            <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>{drone && drone.name ? drone.name : 'Drone info'}</Card.Header>
            <Card.Body>
            {drone && 
                <Container>
                <Row className="mt-0" style={{paddingTop: "0!important"}}>
                    <Col md={6}>
                    <h2>Drone Information</h2>
                    <p><strong>Customer ID:</strong> {drone.customerId}</p>
                    <p><strong>Status:</strong> {drone.status}</p>
                    <Button variant="secondary" onClick={handleShowForm} className="df-button">
                        Edit
                    </Button>
                    </Col>
                </Row>
                {showForm && (
                    <Row className="mt-0">
                    <Col md={6}>
                        <Form onSubmit={handleUpdate}>
                        <Form.Group controlId="formCustomerId">
                            <Form.Label>Customer ID</Form.Label>
                            <Form.Control
                            as="select"
                            value={selectedCustomerId}
                            onChange={(e) => setSelectedCustomerId(e.target.value)}
                            >
                            {customerOptions}
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" size='sm' type="submit" className="df-button">
                            Update
                        </Button>
                        </Form>
                    </Col>
                    </Row>
                )}

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to update the Customer ID for this drone?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmUpdate} className='df-button'>
                        Update
                    </Button>
                    </Modal.Footer>
                </Modal>
                </Container>}
               </Card.Body>
            </Card>
        </div>
  );
};

export default SelectedDroneInfo;
