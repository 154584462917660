import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import axios from "axios";
import useToken from '../../../App/useToken';

const BatteryInfoView = ({batteryStatusList, battery, selectItem, customers, update, setUpdate }) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [newStatus, setNewStatus] = useState(battery.status);
  const [newLocation, setNewLocation] = useState(battery.location);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [customerOptions, setCustomersOptions] = useState([])

  const handleShowForm = () => {
    setShowForm(true);
  };
  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const { token } = useToken()
  const handleUpdate = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleConfirmUpdate = () => {
    // Update the drone's CustomerId here
    // This is where you would typically make an API call to update the database
    const data = {
        'token': token,
        'battery': {
            'serialNumber': battery.serialNumber,
            'customerId': selectedCustomerId != '' && selectedCustomerId != battery.customerId ? selectedCustomerId : battery.customerId,
            'status': newStatus != '' && newStatus != battery.status ? newStatus : battery.newStatus,
            'location': newLocation != '' && newLocation != battery.newLocation ? newLocation : battery.location
        }
    }
    
    console.log(data)
    var config = {
        method: 'post',
        url: "api/update_battery",
        data: data
    };
    axios(config).then(response => {
        console.log(response.data)
        setUpdate(!update)
    }).catch(error => {
        console.log('error', error)
    })
        console.log("has value")
        // Update the drone's CustomerId
    setShowModal(false);
    setShowForm(false);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    // Update the drone's CustomerId here
    // This is where you would typically make an API call to update the database
    const formData = new FormData()
    formData.append('token', token)
    formData.append('serialNumber', battery.serialNumber)

    var config = {
        method: 'post',
        url: "api/delete_battery",
        data: formData
    };
    axios(config).then(response => {
        if(battery.serialNumber === response.data.serialNumber){
            selectItem(null)
        }
        setUpdate(!update)
    }).catch(error => {
        console.log('error', error)
    })
        console.log("has value")
        // Update the drone's CustomerId
    setShowDeleteModal(false);
    setShowForm(false);
  };
  useEffect(()=> {
    if(customers && Array.isArray(customers)){
        let selectCustomerList = [<option value="101010">DF Dev</option>]
        customers.forEach(customer => {
            if(customer.customerId !== "101010"){
                selectCustomerList.push(<option value={customer.customerId}>{customer.name}</option>)
            }
        })
        setCustomersOptions(selectCustomerList)
        // console.log(selectCustomerList)
        // console.log(formRef)
    }
  }, [])

  useEffect(()=>{
    
  },[update, battery])
  return (
    <div style={{
        display: "flex",
        height: "100%",
        width:"100%",
      }}>

            {battery && 
                <Container>
                <Row className="mt-0" style={{paddingTop: "0!important"}}>
                    <Col md={6}>
                    <h2>Battery Information</h2>
                    <p><strong>Customer ID:</strong> {battery.customerId}</p>
                    <p><strong>Status:</strong> {battery.status}</p>
                    <p><strong>Location:</strong> {battery?.location ? battery.location : "-" }</p>
                    <Button variant="secondary" onClick={handleShowForm} className="df-button">
                        Edit
                    </Button>
                    <Button variant="secondary" onClick={handleShowDeleteModal} className="df-button">
                        Delete
                    </Button>
                    </Col>
                </Row>
                {showForm && (
                    <Row className="mt-0">
                    <Col md={6}>
                        <Form onSubmit={handleUpdate}>
                        <Form.Group controlId="formEditBatteryCustomerId">
                            <Form.Label>Customer ID</Form.Label>
                            <Form.Control
                            as="select"
                            value={selectedCustomerId}
                            onChange={(e) => setSelectedCustomerId(e.target.value)}
                            >
                            {customerOptions}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group md="4" controlId="formEditBatteryStatus">

                        <Form.Label>Status</Form.Label>
                            <Form.Select onChange={(e)=> setNewStatus(e.target.value)} defaultValue={battery.status} aria-label="battery status">
                                {
                                    batteryStatusList
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group md="4" controlId="validationCustomBatteryLocation">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Location"
                            defaultValue={battery.location ? battery.location : ""}
                            onChange={(e) => setNewLocation(e.target.value)}
                        />
                        </Form.Group>
                        <Button variant="primary" size='sm' type="submit" className="df-button">
                            Update
                        </Button>
                        </Form>
                    </Col>
                    </Row>
                )}

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <h2>Do you want to update these values </h2>
                    {selectedCustomerId != '' &&  battery.customerId != selectedCustomerId && <p><strong>New customer ID:</strong> {selectedCustomerId}</p>}
                    {battery.status != newStatus && <p><strong>Status:</strong> {newStatus}</p>}
                    {battery.location != newLocation && <p><strong>Lovation:</strong> {newLocation}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmUpdate} className='df-button'>
                        Update
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm delete of {battery.serialNumber}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <h2>Do you want to delete this battery?</h2>
                    <p><strong>Serial number:</strong> {battery.serialNumber}</p>
                    <p><strong>New customer ID:</strong> {battery.customerId}</p>
                    <p><strong>Status:</strong> {battery.status}</p>
                    <p><strong>Location:</strong> {battery.location}</p>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmDelete} className='df-button'>
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
                </Container>}
        </div>
  );
};

export default BatteryInfoView;
