import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

import PropTypes from 'prop-types'
import TreeTableHeader from './TreeTableHeader'
import useToken from '../App/useToken'


export default function ForestDetailsIndividualTrees(props) {
  
  
  // const [itemsToRemove, setItemstoRemove] = useState([])
  // const updateItemsToRemove = (id) => {
  //   let index = itemsToRemove.findIndex(i => i === id )
  //       let temp = [...itemsToRemove]
  //       if(index > -1){
  //           temp.splice(index, 1)
  //       } else {
  //           temp.push(id) 
  //       }
  //       setItemstoRemove(temp)
  // }
  // const {customer} = useToken()
  // const handleClick = () => {
  //   if(props.editMode && itemsToRemove){
  //     props.removeSelectedTrees(itemsToRemove)
  //   }
  // }
  
  // onClick={() => updateItemsToRemove(treeObject.ID)}
  function TreeBody(treeObject) {
    // const style = itemsToRemove.findIndex(i => i === treeObject.ID) > -1 ? {color: 'red' } : {}
    return (
      // color={Array.isArray(props?.selectedItemsToRemove) && props?.selectedItemsToRemove.includes(treeObject.id) ? 'red' : ''} onClick={handleClick(treeObject.ID)}
        <tr key={treeObject.ID} >
          <td>{treeObject.ID}</td>
          <td style={{textAlign:"center", width:"10%"}}>{treeObject.DBH}</td>
          <td style={{textAlign:"center", width:"13%"}}>{treeObject.volume}</td>
          <td style={{textAlign:"center", width:"13%"}}>{treeObject.height}</td>
          <td colSpan={2}  style={{paddingRight: "1.5em", textAlign:"center", width:"34%"}}>{`(${treeObject.lon}, ${treeObject.lat})`}</td>
        </tr>
    )
  }

  const [table, setTable] = useState([])

  useEffect(() => {
    let errors = []
    let zeroPoint1 = []
    let zeroPoint15 = []
    let zeroPoint2 = []
    let zeroPoint25 = []
    let zeroPoint3 = []
    let zeroPoint35 = []
    let zeroPoint4 = []
    let zeroPoint45 = []
    let zeroPoint5 = []
    let zeroPoint55 = []
    let zeroPoint6 = []
    let zeroPoint7 = []
    let zeroPoint8 = []
    let zeroPoint9 = []
    let largest = []

    if(props.trees) {
      props.trees.forEach(tree => {
        if(tree.DBH < 0){
            errors.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.1){
            zeroPoint1.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.15){
            zeroPoint15.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.2){
            zeroPoint2.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.25){
            zeroPoint25.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.3){
            zeroPoint3.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.35){
            zeroPoint35.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.4){
            zeroPoint4.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.45){
            zeroPoint45.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.5){
            zeroPoint5.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.55){
            zeroPoint55.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.6){
            zeroPoint6.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.7){
            zeroPoint7.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.8){
            zeroPoint8.push(
              TreeBody(tree)
            )
            return
        }
        if(tree.DBH <= 0.9){
            zeroPoint8.push(
              TreeBody(tree)
            )
            return
        }
        else {
            largest.push(
              TreeBody(tree)
            )
            return
        }
      })
    }
    
    setTable(props.trees ? (
      <>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.00 - 0.10)' body={zeroPoint1}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.10 - 0.15)' body={zeroPoint15}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.15 - 0.20)' body={zeroPoint2}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.20 - 0.25)' body={zeroPoint25}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.25 - 0.30)' body={zeroPoint3}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.30 - 0.35)' body={zeroPoint35}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.35 - 0.40)' body={zeroPoint4}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.40 - 0.45)' body={zeroPoint45}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.45 - 0.50)' body={zeroPoint5}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.50 - 0.55)' body={zeroPoint55}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.55 - 0.60)' body={zeroPoint6}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.65 - 0.70)' body={zeroPoint7}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.70 - 0.80)' body={zeroPoint8}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.80 - 0.90)' body={zeroPoint9}/>
        <TreeTableHeader open={props.editMode} interval='#ID  (DBH 0.90 - )' body={largest}/>
        <TreeTableHeader open={props.editMode} interval="#ID failed scanned trees" body={errors}/>
      </> ) : null)
  }, [])
  // itemsToRemove
  return (
    
    <div style={{ width: "95%", margin: "1em 1em 1em 1em", height: "fit-content" , border: "3px outset #0f5132"}}>
      {/* <Button style={{border: "2px outset #00d098", marginLeft:"1em"}} size='sm' variant="outline-secondary" onClick={() => props.updateEditMode()}>{props.editMode ? 'Stop editing' : 'Edit'}</Button> */}
      {/* {props.editMode ? <Button style={{border: "2px outset #00d098", marginLeft:"1em"}} size='sm' variant="outline-secondary" onClick={() => handleClick(itemsToRemove)}>Update edits</Button> : null } */}
      {table}
    </div>
  )
}


ForestDetailsIndividualTrees.propTypes = {
  // selectedItemsToRemove: PropTypes.array.isRequired,
  updateEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  trees: PropTypes.array.isRequired,
  selectedBagfile: PropTypes.string.isRequired,
  removeSelectedTrees: PropTypes.func.isRequired,
}