import React, { useEffect, useRef, useState } from 'react';
import Iframe from 'react-iframe'
import PropTypes from 'prop-types'

export default function PotreeViewer(props)
{   
    const itemSelected = props.selectedBagfile ? "The item "+props.selectedBagfile.name+" has not finished it's convertion yet and can't be viewed in 3D" : "Please select a item to view in 3D"
    const view = props.renderPotree && props.selectedBagfile && props.selectedBagfile.processState && props.selectedBagfile.processState.includes("Finished") ?
    (
        <div style={{width:"100%", height:"100%"}}>
            <Iframe src={"potree/app-mounted-file-storage/customers/"+props.selectedBagfile.customerId+"/potree_files/"+props.selectedBagfile.name+".html"}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
        </div>
    ) : <h2>{itemSelected}</h2>
    return view
        
}

PotreeViewer.propTypes = {
    selectedBagfile: PropTypes.object.isRequired,
    setSelectedBagfile: PropTypes.func.isRequired,
    renderPotree: PropTypes.bool.isRequired
}