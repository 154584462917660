import React, {useState,useEffect}  from 'react';
import JSON5 from 'json5'
import axios from 'axios';
import { Button, ListGroup, Tab, Tabs } from 'react-bootstrap'

import useToken from '../App/useToken';
import DroneView from './Drones/droneView';
import ReportView from './Report/reportView';
import PropTypes from 'prop-types'
import FlightReport from './Report/flightReport';


export default function DashboardView(props) {
    const {token} = useToken()
    const [key, setKey] = useState('dashboard');
    // const [selectedCustomer, setSelectedCustomer] = useState(null)
    // const newSelectedCustomer = (customer) => {
    //     setSelectedCustomer(customer)
    // }
    const [customers, setCustomers] = useState([])
    const [drones, setDrones] = useState([])
    const [selectedView, setSelectedView] = useState('drones')
    const selectView = (view) => {
        setSelectedView(view)
    }
    const getDrones = () => {
        var formData = new FormData();
        formData.append('token', token);
        var config = {
            method: 'post',
            url: "api/get_drones",
            data: formData
        };
        axios(config).then(response => {
            const drones = response.data.drones
            
            setDrones(drones)
        }).catch(error => {
            console.log('error', error)
        })
    }
    useEffect(()=> {
        getDrones()
    }, [])

    useEffect(() => {
        // Function to handle incoming WebSocket messages
        const handleMessage = msg => {
            const data = JSON.parse(msg.data);
            if(data.type === "droneConnection" || data.type === "droneStatusUpdate"){     
                getDrones()
            }
            // You can perform actions based on the message content here
        };
        if (props.ws) {
            // Add message listener
            props.ws.addEventListener('message', handleMessage);
        }
        // Clean up the listener when the component unmounts or props.ws changes
    }, [props.ws])

    return (
        <div style={{height: "95%"}}>
            <div>
                <Button onClick={()=>{selectView('dashboard')}}title="Add new drone">Dashboard</Button>
                <Button onClick={()=>{selectView('drones')}}title="Drones">Drones</Button>
                <Button onClick={()=>{selectView('flightReport')}}title="Flight report">Flight Report</Button>
                <Button onClick={()=>{selectView('report')}}title="Create report">Create report</Button>
            </div>
            <div hidden={selectedView !== 'dashboard'}>
                {/* <GeneralView items={customers} type='customer'/> */}
            </div>
            <div hidden={selectedView !== 'drones'}>
                <DroneView items={drones} type='drone' ws={props.ws}/>
            </div>
            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%"
                }}
                hidden={selectedView !== 'flightReport'}
            >
                <FlightReport selectedBagfile={props.selectedBagfile} ctx={props.ctx} bagData={props.bagData}/>
            </div>
            <div style={{
                height: "95%"}}
                hidden={selectedView !== 'report'}>
                <ReportView bagData={props.bagData} ctx={props.ctx} />
            </div>

        </div>
    )
}

DashboardView.propTypes = {
    bagData: PropTypes.array,
    ctx: PropTypes.object.isRequired,
    ws: PropTypes.instanceOf(WebSocket),
    selectedBagfile: PropTypes.object.isRequired,
}