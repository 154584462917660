import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

export default function DeleteDialogPopup(props) {
  return (
    <Modal
      show={props.deleteDialogShow}
      onHide={() => props.setDeleteDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete item {props.itemToDelete ? props.itemToDelete.name : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{color:'#d17e0f'}}>Warning</h4>
        <p>
          Deleting following item will remove uploaded files and data. To view again, a reupload of the file must be done. 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setDeleteDialogShow(false)}>Cancel</Button>
        <Button variant="danger" onClick={() => props.handleDelete(props.itemToDelete)}>Delete</Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DeleteDialogPopup.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  itemToDelete: PropTypes.object.isRequired,
  deleteDialogShow: PropTypes.bool.isRequired,
  setDeleteDialogShow: PropTypes.func.isRequired,
}