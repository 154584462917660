import {useEffect, useState} from 'react';

export const useMediaQuery = (query) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);
  useEffect(() => {
    // set initial value
    const mediaWatcher = window.matchMedia(query)
    setMatches(mediaWatcher.matches);

    //watch for updates
    function updateIsMatches(e) {
      setMatches(e.matches);
    }
    mediaWatcher.addEventListener('change', updateIsMatches)

    // clean up after ourselves
    return function cleanup() {
      mediaWatcher.removeEventListener('change', updateIsMatches)
    }
  })
  return matches;
};