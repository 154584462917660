import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'react-bootstrap'
import useToken from '../App/useToken'
import axios from 'axios'

export default function AddFlightToStandPopup(props) {
  const { token } = useToken()

  const name = props.itemToAdd ? props.itemToAdd.alias : ''
  const updateAID = async (AID) => {
   
    var config = {
      method: "post",
      url: "api/update_dronefile",
      data: {'token': token, '_id': props.itemToAdd._id, 'AID': AID}
    };
    axios(config)
    .then(response => 
     {    
      // console.log(response)
    })
    .catch(error => {
      setErrorMsg(error)
      console.log("error", error)
    })
  } 
  const [AID, setAID] = useState("")
  const [errorMsg, setErrorMsg] = useState()
  const [currentStand, setCurrentStand] = useState({alias: ''})

  const handleSubmit = async (e) => {
    // console.log(alias)
    e.preventDefault()
    if(AID === 'Not ok') {
      setErrorMsg('Please select a stand to continue')
    } else {
      const res = await updateAID(AID)
      console.log(res)
      props.setAddFlightToStandDialogShow(false)
    }
  } 
  const [standOptions, setStandOptions] = useState([])
  useEffect(()=>{
    if(props.addFlightToStandDialogShow){
      getCurrentStand()
      // console.log(props.ctx.filterCustomerData)
      var data = {
        'token' : token,
        'customerId': props.ctx.filterCustomerData
      }
      var config = {
          method: 'post',
          url: "api/get_filtered_surveyarea",
          data: data
      };
      axios(config).then(response => {
          // console.log( response.data)
          var standList = [<option value={'Not ok'}>Select stand to connect item</option>] 
          response.data.surveyAreas.forEach(element => {
            standList.push(<option value={element.AID}>{element.alias}</option>) 
          });
          standList.push(<option value={'NoStand'}>No stand</option>)
          setStandOptions(standList)
      }).catch(error => {
          console.log('error', error)
      })
    } else {
      setCurrentStand({alias:''})
    }
  },[props.addFlightToStandDialogShow])
  const getCurrentStand = () => {
    if(props.itemToAdd && props.itemToAdd.AID){
      // console.log(props.ctx.filterCustomerData)
      var data = {
        'token' : token,
        'AID': props.itemToAdd.AID
      }
      var config = {
          method: 'post',
          url: "api/getSurveyArea",
          data: data
      };
      axios(config).then(response => {
          console.log(response.data.surveyArea)
          setCurrentStand(response.data.surveyArea) 
      }).catch(error => {
          console.log('error', error)
      })
    }
  }
  return (
    <Modal
      show={props.addFlightToStandDialogShow}
      onHide={() => props.setAddFlightToStandDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Choose stand to connect {name} to it 
          <p>Current stand: {currentStand.alias}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>New Stand</Form.Label>
            <Form.Select aria-label=""
                      onChange={(e) => {
                      // console.log(e.currentTarget.value)
                      setAID(e.currentTarget.value)}}>
                          {standOptions}
                    </Form.Select>
            {/* <Form.Control placeholder="Enter new alias" onChange={e => setAlias(e.target.value)}/> */}
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        <aside>         
          <p style={{color: 'orangered'}}>{errorMsg}</p>
        </aside>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setAddFlightToStandDialogShow(false)}>Cancel</Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

AddFlightToStandPopup.propTypes = {
  itemToAdd: PropTypes.object.isRequired,
  addFlightToStandDialogShow: PropTypes.bool.isRequired,
  setAddFlightToStandDialogShow: PropTypes.func.isRequired,
  ctx: PropTypes.object.isRequired
}
