export var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: 'initial',
      right: '2em',
      top: '6em'
    },
    bmBurgerBars: {
      background: "rgb(163 222 0)"
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: "28%",
      minWidth: "250px"
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1em 0',
      fontSize: '1em',
      alignItems: "center",
      height: "calc(100vh - 142px)",
      overflow: "hidden"
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',  
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      height: "95%",
      minHeight: "350px"
    },
    bmItem: {
      display: 'inline-block',
      padding: "0",
      margin: "0",
      marginBottom: "1em",
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  export var rowStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '36px',
      height: '30px',
      left: 'initial',
      right: '2em',
      top: '200px'
    },
    bmBurgerBars: {
      background: "rgb(163 222 0)"
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      width: "28%",
      minWidth: "250px"
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1em',
      height: "calc(100vh - 142px)",
      overflow: "hidden"
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',  
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "relative",
      height: "95%",
      minHeight: "350px"   
    },
    bmItem: {
      display: 'inline-block',
      marginBottom: "1em",
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  