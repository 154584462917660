import React, { useEffect, useState,useRef } from 'react';
import { Card} from 'react-bootstrap';
import PropTypes from 'prop-types'

const DroneStatus = ({item, ws}) => {
    const [currentDrone, setCurrentDrone] = useState(item)
    const currentDroneRef = useRef(currentDrone);

    useEffect(() => {
        // Update the ref whenever currentDrone changes
        currentDroneRef.current = currentDrone;
    }, [currentDrone]);
    const offlineData = [
        // { field: 'Drone number', value: '0005 of 5145' },
        { field: 'Location', value: '-' },
        { field: 'Temperature', value: '-' },
        { field: 'Free storage', value: '-' },
        { field: 'Network speed', value: '-' },
        { field: 'Up time', value: '-' },
        { field: 'Upload progress', value: '-' },
        { field: 'Estimated upload time left', value: '-' },
        { field: 'Area Files synched', value: item && item.areaFiles ? item.areaFiles.length : '-' },

        // { field: 'Crop type', value: 'Legume' },
        // { field: 'GPS location', value: '37.7749° N, 122.4194° W'  },
        // { field: 'Flight time conducted', value: '10min' },
        // { field: 'Flight time remaining', value: '25min' },
        // { field: 'Average flights per day', value: '6' },
        // { field: 'Regulator', value: 'Madhya Pradesh Province (Id – 55483)' }
      ];
    const [droneData, setDroneData] = useState(offlineData)
    const droneDataRef = useRef(droneData)
    const updateDroneData = (updateData) => {
        const update = droneDataRef.current.map(field => {
            // Check if the field name exists in the updateData object
            if (updateData.hasOwnProperty(field.field)) {
                // Update the value of the field with the corresponding value from updateData
                return { ...field, value: updateData[field.field] };
            }
            // Return the field as is if no update is needed
            return field;
        });
        droneDataRef.current = update
        setDroneData(update)    
    };
    useEffect(() => {
        droneDataRef.current = offlineData
        setDroneData(offlineData)
        setCurrentDrone(item); // Sync with the new item
    }, [item]);
    useEffect(() => {
        // Function to handle incoming WebSocket messages
        const handleMessage = msg => {
            const data = JSON.parse(msg.data);
            console.log(data)
            console.log(currentDroneRef.current)
            if(data.type === "updateDroneEvent" && data.eventData.dronename == currentDroneRef.current.name){     
                updateDroneData(data.eventData.droneData)
            }
            // You can perform actions based on the message content here
        };
        if (ws) {
            // Add message listener
            ws.addEventListener('message', handleMessage);
        }
        // Clean up the listener when the component unmounts or props.ws changes
    }, [ws])
    //   Location - String
    //   Temperature - String
    //   Free storage - String
    //   Network speed - String
    //   Up time - String
    //   Upload progress - String
    //   Estimated upload time left - String
    
    // const droneData = [
    //     { field: 'Battery Level', value: '85%' },
    //     { field: 'GPS Signal', value: 'Strong' },
    //     { field: 'Altitude', value: '150 meters' },
    //     { field: 'Speed', value: '20 km/h' },
    //     { field: 'Camera Status', value: 'Active (Recording)' },
    //     { field: 'Last Maintenance', value: '2024-06-15' },
    //     { field: 'Temperature', value: '22°C' },
    //     { field: 'Wind Speed', value: '5 km/h' },
    //     { field: 'Flight Mode', value: 'Autonomous' },
    //     { field: 'Distance Traveled', value: '3.2 km' },
    //     { field: 'Area covered', value: '5.3 ha' },
    //     { field: 'GPS Coordinates', value: '37.7749° N, 122.4194° W' }
    //   ];
    
    // Split the data into two columns
    const middleIndex = Math.ceil(droneDataRef.current.length / 2);
    const firstColumnData = droneDataRef.current.slice(0, middleIndex);
    const secondColumnData = droneDataRef.current.slice(middleIndex);

    const tableStyle = {
        width: '100%',
        textAlign: 'left',
        fontSize: '1em'
    };

    const cellStyle = {
     padding: '5px 10px'
    };

    const columnStyle = {
        verticalAlign: 'top',
        padding: '5px'
    };
  return (
    <div style={{
        display: "flex",
        width:"100%",
      }}>
        <Card 
            bg={"dark"}
            key={"AdminSelectedItem"}
            text={"white"}
            style={{minWidth: "95%", margin: "1em", border: "3px outset #0f5132"}}
            className="mb-3">
            <Card.Header style={{fontSize: "1.3em", fontWeight: "bold", marginBottom: "1em" }}>{currentDrone && currentDrone.name !== '' ? currentDrone.name : '-'}</Card.Header>
            <Card.Body>
            <table style={tableStyle}>
                <tbody>
                <tr>
                    <td style={columnStyle}>
                    <table>
                        <tbody>
                        {firstColumnData.map((data, index) => (
                            <tr key={index}>
                            <td style={cellStyle}><strong>{data.field}</strong></td>
                            <td style={cellStyle}>{data.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </td>
                    <td style={columnStyle}>
                    <table>
                        <tbody>
                        {secondColumnData.map((data, index) => (
                            <tr key={index}>
                            <td style={cellStyle}><strong>{data.field}</strong></td>
                            <td style={cellStyle}>{data.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </td>
                </tr>
                </tbody>
            </table>
            </Card.Body>
        </Card>
    </div>
  );
};

export default DroneStatus;