const geolocateStyle = {
    top: 0,
    left: 0,
    padding: '1em'
  };
  
const fullscreenControlStyle = {
  top: 36,
  left: 0,
  padding: '1em'
};

const navStyle = {
  top: 72,
  left: 0,
  padding: '1em'
};

const scaleControlStyle = {
  bottom: 36,
  left: 0,
  padding: '1em'
};

const dashboardStyle =
{
  display: "flex",
  width: "100%",
  height: "100%",
  position: "fixed"
}
const mapStyle = {
  width: "100%",
  height: "100%"
}
export const styles = {
      geolocateStyle: geolocateStyle,
      fullscreenControlStyle: fullscreenControlStyle,
      navStyle: navStyle,
      scaleControlStyle: scaleControlStyle,
      dashboardStyle: dashboardStyle,
      mapStyle: mapStyle
  }