import React, { useEffect, useState, useRef }  from 'react';
import Map, {
    Layer,
    Source,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Popup
   } from 'react-map-gl';
import MAP_STYLE from '../../../data/style.json'
import { styles } from './styles'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';
// Be sure to include styles at some point, probably during your bootstraping
import PropTypes from 'prop-types'
import useCoord from '../../App/useCoord';
import { Card } from 'react-bootstrap';

// { type: "Feature", "properties": { "id": "ak16994521", "mag": 2.3, "time": 1507425650893, "felt": null, "tsunami": 0 }, "geometry": { "type": "Point", "coordinates": [ -151.5129, 63.1016, 0.0 ] } },

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
const MAPBOX_TOKEN = process.env.REACT_APP_NOT_SECRET_CODE3

export default function ReportMap(props) {
  const [popupInfo, setPopupInfo] = useState(null)
  const [popupInfoCoord, setPopupInfoCoord] = useState({longitude: null, latitude: null})
  const {coord} = useCoord()
  const defaultCoord = useRef(coord)

  const onMapClick = event => {
    // console.log(event.features)
    
    const feature = event.features[0];
    const layer = feature && feature.layer;
    if(feature && layer.id === 'clusters' && props.showCluster){
      const clusterId = feature.properties.cluster_id;

      const mapboxSource = props.mapref.current.getSource('markers');

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        props.mapref.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 700,
        });
      });
    }
    else if (feature && layer.id === 'unclustered-point'){
      // console.log(feature.properties)
      const properties = feature.properties
      if(!props?.selectedBagfiles?.some(i => i._id === properties._id)){
        props.setSelectedBagfiles(properties)
      }
      
      setPopupInfoCoord(properties)
    }
  };

  useEffect(()=> {
    if(popupInfoCoord.latitude){
      const info =  
      (<Popup
        anchor="top"
        longitude={popupInfoCoord.longitude}
        latitude={popupInfoCoord.latitude}
        onClose={() => {
          setPopupInfo(null)
          setPopupInfoCoord({longitude: null, latitude: null})
        }}
      >
        <div>
          {popupInfoCoord.alias ? popupInfoCoord.alias : popupInfoCoord.name}
          <p>{'area (hectare): '+Number(popupInfoCoord.area).toFixed(3)}</p>
        </div>
      </Popup>)
      setPopupInfo(info)
      // const features = props.mapref.current.queryRenderedFeatures({layers: ['unclustered-point']})
      const features = props.mapref.current.querySourceFeatures('markers');
      //console.log(features)
    }
  },[popupInfoCoord])
  const transitionEnded = () =>{
    // console.log('transition ended')
    if(props.mapref.current){

      const features = props.mapref.current.querySourceFeatures('markers');
      if(Array.isArray(props?.selectedBagfiles)) {
        features.forEach(f => { 
            const selected = props?.selectedBagfiles?.some(i => i._id === f.properties._id)
            //console.log(selected)
            props.mapref.current.setFeatureState(
              { source: 'markers', id: f.id },
              { selected: selected }
            )
        })
        // let feature = features.filter(f => f.properties._id === props.selectedBagfile._id)
        // console.log('feature selected: '+feature)
        // if(feature){
        //   if(feature.length > 1){
        //     feature = feature[0]
        //   }
        //   props.mapref.current.setFeatureState(
        //     { source: 'markers', id: feature.id },
        //     { selected: true }
        //     );
        // }
      }
    }
  }

  const dataLayer = {
    id: 'polygon',
    type: 'fill',
    source: 'potree-zones',
    paint: {
      'fill-color': '#aaa700',
      'fill-opacity': 0.6
    }
  }
  const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'markers',
    filter: ['has', 'point_count'],
    paint: {
      "circle-color": "#f1f075",
      "circle-radius": 30
    }
  }
  
  const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'markers',
    
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
      "text-allow-overlap" : true
    }
  };
  
  const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'markers',
    sourceLayer: 'points',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        '#c71616',
        '#0032e6'
      ],
      'circle-radius':  [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        7,
        5],
      'circle-stroke-width': [
        'case', 
        ['boolean',['feature-state', 'selected'], false],
        2,
        1],
      'circle-stroke-color': '#fff'
    }
  };
  
  const [allPolygonData, setAllPolygonData] = useState({
    type: "FeatureCollection",
    features: []
  })
  const [allPointerData, setAllPointerData] = useState({
    type: 'FeatureCollection',
    features: []
  })
  useEffect(()=> {
    if(props.mapref.current){
      const polySource = props.mapref.current.getSource('potree-zones');
      const pointSource = props.mapref.current.getSource('markers');
    //   if(props.ctx.showShapeData?.current){
    //     if(Array.isArray(props.ctx.shapeData?.current)){
    //       const updatedPointerData = []
    //       props.ctx.shapeData?.current.forEach((item) => {
    //         item.geojson.features.forEach(feature => {
    //           let type = feature.geometry.type
    //           if(String(type).toLowerCase().includes('polygon')){
    //             let coords = feature.geometry.coordinates
    //             if(String(type).toLowerCase() === 'multipolygon'){
    //               coords =  coords[0][0][0]
    //             } else {
    //               coords =  coords[0][0]
    //             }
    //             updatedPointerData.push(
    //               {
    //                 type: 'Feature',
    //                 id: item._id,
    //                 properties: item,
    //                 geometry: {
    //                   type: 'Point',
    //                   coordinates: coords
    //                 }
    //             })
    //           } else {
    //             updatedPointerData.push(
    //               {
    //                 type: 'Feature',
    //                 id: item._id,
    //                 properties: item,
    //                 geometry: {
    //                   type: 'Point',
    //                   coordinates: feature.geometry.coordinates
    //                 }
    //             })
                
    //           }
              
    //         })
              
    //       })
    //       const updatedPolygonData = []
    //       props.ctx.shapeData?.current.forEach(item => {
    //         item.geojson.features.forEach(feature => {
    //           updatedPolygonData.push(feature)
    //         })
    //       })
    //       // console.log(updatedPointerData)
    //       // console.log(updatedPolygonData)
    //       polySource.setData({
    //         "type": "FeatureCollection",
    //         "features": updatedPolygonData
    //       })
    //       pointSource.setData({
    //         "type": "FeatureCollection",
    //         "features": updatedPointerData
    //     })
          
    //     }
    //   } else {
        if(props.geoData){
          const updatedPointerData = []
          props.geoData.forEach((item) => {
            if(item.longitude){
              updatedPointerData.push(
                {
                  type: 'Feature',
                  id: item._id,
                  properties: item,
                  geometry: {
                    type: 'Point',
                    coordinates: item.latitude ? [item.longitude, item.latitude] : []
                  }
                }
              ) 
            }   
          })
          
          
          const updatedPolygonData = []
          
          props.geoData.forEach((item) => {
            updatedPolygonData.push( 
              {
                type: 'Feature',
                properties: {id:item._id, area: item.area, alias: item.alias ? item.alias : item.name},
                geometry: {
                  coordinates: item.polygon ? [item.polygon] : [],
                  type: "Polygon"
                }
              })
            })
            polySource.setData({
              "type": "FeatureCollection",
              "features": updatedPolygonData
            })
            pointSource.setData({
              "type": "FeatureCollection",
              "features": updatedPointerData
          })
        }
  }
    // console.log(props.geoData)
  },[props.geoData])

  
  const controllers = props.hasControlers ? (
      <>
          <GeolocateControl style={styles.geolocateStyle} />
          <FullscreenControl style={styles.fullscreenControlStyle} />
          <NavigationControl style={styles.navStyle} />
          <ScaleControl style={styles.scaleControlStyle} /> 
      </>
      ) : null  
  const interactiveLayerIds = props.showCluster ? [clusterLayer.id, dataLayer.id, unclusteredPointLayer.id] : [dataLayer.id, unclusteredPointLayer.id]
  
  return(
    <Card 
    bg={"dark"}
    key={"ForestDetailsItems"}
    text={"white"}
    style={{minWidth: "200px", height: "100%", margin: "1em", border: "3px outset #0f5132"}}
    className="mb-3">
    <div style={props.mapStyle}>
        <Map  
          initialViewState={{
            latitude: defaultCoord.current.latitude,
            longitude: defaultCoord.current.longitude,
            zoom: 6
          }}        
          width={props.width}
          height={props.height}
          // onViewportChange={props.setViewport}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={MAP_STYLE}
          interactiveLayerIds={interactiveLayerIds}
          onClick={onMapClick}
          ref={props.mapref}
          onMoveEnd={() => transitionEnded()}
          //interactiveLayerIds={['polygon']}
          // onClick={onClick}
        >
          {controllers}
          <Source id='potree-zones' type='geojson' 
          // data={allPolygonData}
          >
            <Layer
            {...dataLayer}
            />
          </Source>
          <Source 
            id='markers' 
            type='geojson' 
            // data={allPointerData} 
            cluster={props.showCluster}
            clusterMaxZoom={14}
            clusterRadius={25}
            generateId={true}
            // clusterMaxZoom={14}
            // clusterRadius={50}
          >
            {props.showCluster && <Layer {...clusterLayer}/>}
            {props.showCluster && <Layer {...clusterCountLayer} />}
            <Layer {...unclusteredPointLayer} />
          </Source>
          {popupInfo}
          {props.pins}
          {/* {props.dropZone} */}
        </Map>
    </div>
    </Card>
  );
}

ReportMap.propTypes = {
    selectedBagfiles: PropTypes.object.isRequired,
    setSelectedBagfiles: PropTypes.func.isRequired,
    mapref: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    hasControlers: PropTypes.bool.isRequired,
    mapStyle: PropTypes.object.isRequired,
    // dropZone: PropTypes.object,
    popup: PropTypes.object,
    popupInfo: PropTypes.object,
    setPopupInfo: PropTypes.func,
    geoData: PropTypes.array,
    pins: PropTypes.array,
    showCluster: PropTypes.bool.isRequired,
    ctx: PropTypes.object.isRequired,
}