import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

export default function DeleteStandDialogPopup(props) {
  return (
    <Modal
      show={props.deleteStandDialogShow}
      onHide={() => props.setDeleteDialogShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div style={{backgroundColor: "#212529", color: "#ffffff"}}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete stand {props.standToDelete ? props.standToDelete.name : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 style={{color:'#d17e0f'}}>Warning</h4>
        <p>
          Deleting following stand will remove, stand data, unlink any flights to this stand and delete uploaded file. To view again, a reupload of the file must be done and manually relink flights. 
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.setDeleteStandDialogShow(false)}>Cancel</Button>
        <Button variant="danger" onClick={() => props.handleDeleteStand(props.standToDelete)}>Delete</Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

DeleteStandDialogPopup.propTypes = {
  handleDeleteStand: PropTypes.func.isRequired,
  standToDelete: PropTypes.object.isRequired,
  deleteStandDialogShow: PropTypes.bool.isRequired,
  setDeleteStandDialogShow: PropTypes.func.isRequired,
}