export const appStyle = {
    width: "100%",
    height: "100px",
    backgroundColor: "#262323",
}

export const narrowAppStyle = {
    width: "100%",
    height: "100px",
    minHeight: "67px",
    backgroundColor: "#262323",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
}