import React, { useState } from 'react'

export default function useCoord() {
  const getCoord= () => {
    return {
      latitude: Number(sessionStorage.getItem('latitude')), 
      longitude: Number(sessionStorage.getItem('longitude'))
    }
  }

  const [coord, setCoord] = useState(getCoord())

  const saveCoord= userCoord=> {
    // console.log(userCoord)
    sessionStorage.setItem('latitude',  Number(userCoord.latitude.toFixed(3)))
    sessionStorage.setItem('longitude', Number(userCoord.longitude.toFixed(3)))
    setCoord(userCoord)
  }

  const removeCoord= () => {
    sessionStorage.removeItem('latitude')
    sessionStorage.removeItem('longitude')
    setCoord(null)
  }
  return {
    setCoord: saveCoord,
    removeCoord,
    coord,
  }
}
